import { combineReducers } from 'redux';
import counter from './counter';
import news from './news';
import intl from './intl';

const reducer = combineReducers({
  counter,
  intl,
  news
});

export default reducer;
