import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const InvestmentOpportunities = () => {
  return (
    <Root>
      <Container>
        <ColumnTwo>
          <Column>
            <h2>Investment opportunities</h2>
          </Column>
          <Column>
            <p>
            Enera has a long tradition of working with a select group of investors on a range of projects and developments and we are always looking to expand our network with partners who share our vision and values.
              <br />
              <br />
              For more information of upcoming investment opportunities, please contact us at:
              <br />
              <br />
              <a
                href="mailto:partnerships@enera.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                partnerships@enera.ca
              </a>
            </p>
          </Column>
        </ColumnTwo>
      </Container>
    </Root>
  );
};

export default InvestmentOpportunities;

const Root = styled.div`
  background-color: white;
  padding-top: ${vw(577)};
  padding-bottom: ${vw(150)};
  position: relative;
  overflow-x: hidden;
  @media (max-width: ${media.sm}) {
    padding-top: ${vwTablet(331)};
  }
  @media (max-width: ${media.xs}) {
    padding-top: ${vwMobile(116)};
    padding-bottom: ${vwMobile(40)};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${require('src/assets/images/about/bg-shapes/shape_3.png')});
    background-size: cover;
    pointer-events: none;
  }
  h2 {
    font-family: ${({ theme }) => theme.fontHeader};
    font-weight: normal;
    font-size: ${vw(38)};
    line-height: ${vw(32)};
    color: white;
    @media (max-width: ${media.sm}) {
      margin-bottom: ${vwTablet(30)};
      font-size: ${vwTablet(36)};
      line-height: ${vwTablet(36)};
    }
    @media (max-width: ${media.xs}) {
      margin-bottom: ${vwMobile(20)};
      font-size: ${vwMobile(24)};
      line-height: ${vwMobile(32)};
    }
  }
  p {
    font-family: ${({ theme }) => theme.fontBody};
    font-size: ${vw(16)};
    line-height: ${vw(26)};
    color: white;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(26)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(18)};
    }
  }
  a {
    color: ${({ theme }) => theme.colorOrange};
    position: relative;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(14)};
      line-height: ${vwTablet(21)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(21)};
    }
    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      border-bottom: 1px solid ${({ theme }) => theme.colorOrange};
      width: 0;
      height: 1px;
      transition: width 0.4s ease-out;
    }
    &:hover {
      &::before {
        width: 100%;
      }
    }
  }
`;

const Container = styled.div`
  width: ${vw(1200)};
  margin: 0 auto;
  margin-left: 12%;
  @media (max-width: ${media.sm}) {
    margin-left: 16%;
  }
  @media (max-width: ${media.xs}) {
    margin-left: 8.33%;
  }
`;

const ColumnTwo = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: ${media.sm}) {
    flex-direction: column;
    margin-left: ${vwTablet(30)};
  }
  @media (max-width: ${media.xs}) {
    flex-direction: column;
    margin-left: 0;
  }
`;

const Column = styled.div`
  width: 50%;
  z-index: 1;
  @media (max-width: ${media.sm}) {
    width: 75%;
  }
  @media (max-width: ${media.xs}) {
    width: 100%;
  }
`;

// const BGShapeBottom = styled.img`
//   width: 100%;
//   height: 100%;
//   background: url(${require('src/assets/images/about/bg-shapes/shape_3.png')});
//   background-size: contain;
//   background-repeat: no-repeat;
//   pointer-events: none;
// `;
