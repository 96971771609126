import React from 'react';

import InvestProject from '../elements/InvestProject';

const Hurd = () => {
  return (
    <InvestProject
      name="7298 Hurd St."
      location="Mission, BC"
      type="25,894 Sq. Ft. Rentable Space"
      size="3-Storey/42,453 Sq. Ft."
      lease="Fraser Health Authority Lease"
      image={require('src/assets/images/investments/2.jpg')}
    />
  );
};

export default Hurd;
