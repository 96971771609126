import React from 'react';
import styled from 'styled-components';

const Instagram = () => {
  return (
    <Root
      width="25px"
      height="25px"
      viewBox="0 0 25 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>instagram</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Desktop"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Contact"
          transform="translate(-657.000000, -600.000000)"
          fill="#FFFFFF"
        >
          <g id="instagram" transform="translate(657.000000, 600.000000)">
            <path
              d="M18.0008824,0 L6.86088235,0 C3.07779412,0 0,3.07794118 0,6.86102941 L0,18.0010294 C0,21.7842647 3.07779412,24.8620588 6.86088235,24.8620588 L18.0008824,24.8620588 C21.7842647,24.8620588 24.8620588,21.7841176 24.8620588,18.0010294 L24.8620588,6.86102941 C24.8622059,3.07794118 21.7842647,0 18.0008824,0 Z M22.6563235,18.0010294 C22.6563235,20.5679412 20.5679412,22.6561765 18.0010294,22.6561765 L6.86088235,22.6561765 C4.29411765,22.6563235 2.20588235,20.5679412 2.20588235,18.0010294 L2.20588235,6.86102941 C2.20588235,4.29426471 4.29411765,2.20588235 6.86088235,2.20588235 L18.0008824,2.20588235 C20.5677941,2.20588235 22.6561765,4.29426471 22.6561765,6.86102941 L22.6561765,18.0010294 L22.6563235,18.0010294 Z"
              id="Shape"
              fill-rule="nonzero"
            />
            <path
              d="M12.4310294,6.025 C8.89852941,6.025 6.02470588,8.89882353 6.02470588,12.4313235 C6.02470588,15.9636765 8.89852941,18.8373529 12.4310294,18.8373529 C15.9635294,18.8373529 18.8373529,15.9636765 18.8373529,12.4313235 C18.8373529,8.89882353 15.9635294,6.025 12.4310294,6.025 Z M12.4310294,16.6313235 C10.115,16.6313235 8.23058824,14.7472059 8.23058824,12.4311765 C8.23058824,10.115 10.1148529,8.23073529 12.4310294,8.23073529 C14.7472059,8.23073529 16.6314706,10.115 16.6314706,12.4311765 C16.6314706,14.7472059 14.7470588,16.6313235 12.4310294,16.6313235 Z"
              id="Shape"
              fill-rule="nonzero"
            />
            <path
              d="M19.1060294,4.15455882 C18.6810294,4.15455882 18.2635294,4.32661765 17.9633824,4.62808824 C17.6617647,4.92808824 17.4883824,5.34573529 17.4883824,5.77220588 C17.4883824,6.19735294 17.6619118,6.61485294 17.9633824,6.91632353 C18.2633824,7.21632353 18.6810294,7.38985294 19.1060294,7.38985294 C19.5325,7.38985294 19.9486765,7.21632353 20.2501471,6.91632353 C20.5516176,6.61485294 20.7236765,6.19720588 20.7236765,5.77220588 C20.7236765,5.34573529 20.5516176,4.92808824 20.2501471,4.62808824 C19.9501471,4.32661765 19.5325,4.15455882 19.1060294,4.15455882 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </Root>
  );
};

const Root = styled.svg`
  width: 100%;
  height: auto;
  &:hover {
    g {
      fill: #ff8300;
      transition: fill 0.4s ease;
    }
  }
`;

export default Instagram;
