import React, { Component } from 'react';
import styled from 'styled-components';
import Waypoint from 'react-waypoint';

import Description from 'components/Description';
import Logo from 'components/Logo';
import ProjectCard from 'components/ProjectCard';

class LayoutWithProjects extends Component {
  state = {
    unstuck: false,
  };

  _handleWaypointEnter = () => {
    this.setState({ unstuck: true });
  };

  _handleWaypointLeave = () => {
    this.setState({ unstuck: false });
  };

  render() {
    const { text, projects, type } = this.props;
    console.log(this.state.unstuck);
    return (
      <Root unstuck={this.state.unstuck}>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>

        <ContentUnderLogo>
          <Description text={text} />
        </ContentUnderLogo>

        <ContentOverLogo>
          {projects.map((project, index) => (
            <ProjectCard project={project} key={index} type={type} />
          ))}
        </ContentOverLogo>
        <Offset />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
          // topOffset={200}
          // bottomOffset={200}
        />
      </Root>
    );
  }
}

export default LayoutWithProjects;

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${props => (props.unstuck ? '-1' : '')};
`;

const LogoWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  padding-top: 40px;
  padding-left: 50px;
  z-index: 999;
  width: 200px;
`;

const ContentUnderLogo = styled.div`
  background: ${({ theme }) => theme.colorLightBlue};
  padding-left: 190px;
`;

const ContentOverLogo = styled.div`
  z-index: 9999;
  background: green;
  position: relative;
`;

const Offset = styled.div`
  height: 648px;
  pointer-events: none;
`;
