import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const InvestProject = ({
  name,
  location,
  type,
  size,
  lease,
  image,
  isDesktopReverse,
}) => {
  return (
    <Root reverse={isDesktopReverse}>
      <Info>
        <h3>{name}</h3>
        <Block>
          {location && (
            <Item>
              <h4>Location</h4>
              <p>{location}</p>
            </Item>
          )}
          {type && (
            <Item>
              <h4>Type</h4>
              <p>{type}</p>
            </Item>
          )}
          {size && (
            <Item>
              <h4>Size</h4>
              <p>{size}</p>
            </Item>
          )}
          {lease && (
            <Item>
              <h4>Lease</h4>
              <p>{lease}</p>
            </Item>
          )}
        </Block>
      </Info>
      <ImageBlock image={image} />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  font-family: ${({ theme }) => theme.fontBody};
  position: relative;
  z-index: 10;
  @media (max-width: ${media.sm}) {
    flex-direction: column;
  }
`;

const Info = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colorDarkBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${vw(157)} 0;
  padding-left: ${vw(124)};
  position: relative;
  z-index: 11;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 95%, 0);
    background: url(${require('src/assets/images/investments/triangle.svg')});
    background-size: cover;
    width: 60px;
    height: 30px;
    display: none;
    @media (max-width: ${media.sm}) {
      display: block;
      width: ${vwTablet(60)};
      height: ${vwTablet(30)};
    }
    @media (max-width: ${media.xs}) {
      display: block;
      width: ${vwMobile(40)};
      height: ${vwMobile(20)};
    }
  }
  @media (max-width: ${media.sm}) {
    width: 100%;
    padding: ${vwTablet(60)} 0;
    padding-left: 8.33%;
  }
  @media (max-width: ${media.sm}) {
    width: 100%;
    padding: ${vwMobile(44)} 0;
    padding-left: 8.33%;
  }
  h3 {
    font-size: ${vw(20)};
    font-weight: 600;
    line-height: ${vw(32)};
    letter-spacing: ${vw(1.5)};
    color: white;
    text-transform: uppercase;
    margin-bottom: ${vw(40)};
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(18)};
      font-weight: 600;
      line-height: ${vwTablet(32)};
      letter-spacing: ${vwTablet(1.4)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(16)};
      font-weight: 600;
      line-height: ${vwMobile(24)};
      letter-spacing: ${vwMobile(0.5)};
    }
  }
`;

const Block = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  @media (max-width: ${media.xs}) {
    grid-template-columns: auto;
  }
`;

const Item = styled.div`
  margin-bottom: ${vw(20)};
  @media (max-width: ${media.sm}) {
    margin-bottom: ${vwTablet(20)};
  }
  @media (max-width: ${media.xs}) {
    margin-bottom: ${vwMobile(20)};
  }
  h4 {
    font-size: ${vw(13)};
    line-height: ${vw(13)};
    letter-spacing: ${vw(1.5)};
    color: ${({ theme }) => theme.colorGray};
    text-transform: uppercase;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(13)};
      line-height: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1.5)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(12)};
      line-height: ${vwMobile(12)};
      letter-spacing: ${vwMobile(1)};
    }
  }
  p {
    opacity: 0.8;
    font-size: ${vw(16)};
    line-height: ${vw(26)};
    color: white;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(13)};
      line-height: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1.5)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(18)};
      letter-spacing: 0;
    }
  }
`;

const ImageBlock = styled.div`
  width: 50%;
  background: url(${props => props.image});
  background-size: cover;
  z-index: 1;
  @media (max-width: ${media.sm}) {
    width: 100%;
    height: ${vwTablet(521)};
  }
  @media (max-width: ${media.xs}) {
    width: 100%;
    height: ${vwMobile(217)};
  }
`;

export default InvestProject;
