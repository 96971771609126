import React from 'react';

import InvestProject from '../elements/InvestProject';

const SunridgeWay = () => {
  return (
    <InvestProject
      name="2752 Sunridge Way"
      location="Calgary, BC"
      type="45,319 Rentable Space"
      size="3-Storey"
      lease="Long-Term Government of Alberta lease"
      image={require('src/assets/images/investments/5.jpg')}
      isDesktopReverse
    />
  );
};

export default SunridgeWay;
