import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

// import Container from 'components/Container';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const LastSection = ({ children }) => {
  return <Root>{children}</Root>;
};

const Root = styled.div`
  margin-bottom: ${vw(575)};
  @media (max-width: ${media.sm}) {
    margin-bottom: ${vwTablet(420)};
  }
  @media (max-width: ${media.xs}) {
    margin-bottom: ${vwMobile(380)};
  }
`;

export default LastSection;