import React, { PureComponent } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import ScrollAnimation from 'components/ScrollAnimation';
import { Parallax, withController } from 'react-scroll-parallax';

import Container from 'components/Container';
import Button from 'components/Button';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

class Redefine extends PureComponent {
  handleLoad = () => {
    // updates cached values after image dimensions have loaded
    this.props.parallaxController.update();
  };
  
  render() {
    return (
      <Root>
        <BGShapeTop>
          <Parallax y={[1, -20]} tagOuter="figure">
            <img
              src={require('src/assets/images/home/bg-shapes/shape_3.png')}
              alt="bg-shape"
              onLoad={this.handleLoad}
            />
          </Parallax>
        </BGShapeTop>

        <BGShapeBottom>
          <Parallax y={[50, -50]} tagOuter="figure">
            <img
              src={require('src/assets/images/home/bg-shapes/shape_4.png')}
              alt="bg-shape"
              onLoad={this.handleLoad}
            />
          </Parallax>
        </BGShapeBottom>
        <Container>
          <ScrollAnimation>
            <Parallax y={[-300, 300]}>
              <TextWrapper>
                <h1>Redefining Tomorrow</h1>
                <p>
                  Headquartered in Vancouver, Canada, Enera Enterprises
                  specializes in commercial and residential real estate
                  acquisition, management, and development
                </p>
                <Button label="About" link="/about" />
              </TextWrapper>
            </Parallax>
          </ScrollAnimation>
        </Container>
      </Root>
    );
  }
}

const Root = styled.div`
  padding: ${vw(205)} 0;
  background: url(${require('src/assets/images/home/redefining_tomorrow.jpg')});
  background-size: cover;
  background-position: center 100%;
  position: relative;
  overflow: hidden;
  padding-top: ${vw(205)};
  @media (max-width: ${media.sm}) {
    padding-top: ${vwTablet(101)};
    padding-bottom: ${vwTablet(100)};
  }
  @media (max-width: ${media.xs}) {
    padding-top: ${vwMobile(30)};
    padding-bottom: ${vwMobile(50)};
  }
`;

const BGShapeTop = styled.div`
  position: absolute;
  top: -65%;
  right: 0;
  width: 70%;
  height: auto;
  z-index: 1;
  pointer-events: none;
  @media (max-width: ${media.sm}) {
    width: 100%;
  }
  & img {
    width: 100%;
    height: auto;
  }
`;

const BGShapeBottom = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 0;
  pointer-events: none;
  & img {
    width: 100%;
    height: auto;
  }
`;

const TextWrapper = styled.div`
  width: 45.45%;
  color: white;
  z-index: 1;
  position: relative;
  @media (max-width: ${media.sm}) {
    width: 72.72%;
  }
  @media (max-width: ${media.xs}) {
    width: 91.91%;
  }
  h1 {
    font-family: ${({ theme }) => theme.fontHeader};
    font-size: ${vw(38)};
    line-height: ${vw(32)};
    font-weight: 400;
    margin-bottom: ${vw(40)};
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(36)};
      line-height: ${vwTablet(36)};
      margin-bottom: ${vwTablet(27)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(24)};
      line-height: ${vwMobile(32)};
      margin-bottom: ${vwMobile(27)};
    }
  }
  p {
    font-family: ${({ theme }) => theme.fontBody};
    letter-spacing: ${vw(0.5)};
    line-height: ${vw(30)};
    font-size: ${vw(20)};
    font-weight: 300;
    margin-bottom: ${vw(40)};
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(18)};
      line-height: ${vwTablet(28)};
      letter-spacing: ${vwTablet(0.5)};
      margin-bottom: ${vwTablet(40)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(20)};
      margin-bottom: ${vwMobile(40)};
    }
  }
`;

export default withController(Redefine);
