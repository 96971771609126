import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'src/styles/media';

import Container from 'components/Container';
import Button from 'components/Button';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import imgWordmark from 'src/assets/images/logo/wordmark.svg';
import imgWordmark2 from 'src/assets/images/logo/wordmark-2.svg';

const Footer = ({ unstuck }) => {
  return (
    <Root forward={unstuck}>
      <LogoWrapper unstuck={unstuck} href="/">
        <img src={require('src/assets/images/logo/e_logo.svg')} alt="logo" />
      </LogoWrapper>

      <StyledContainer>
        <Left>
          <WordmarkWrapper>
            <img src={imgWordmark} alt="Enera" />
            <img src={imgWordmark2} alt="Enterprises" />
          </WordmarkWrapper>

          <Contact>
            <a
              href="https://www.google.com/maps/place/550+W+Broadway,+Vancouver,+BC+V5Z+0A9/data=!4m2!3m1!1s0x548673dd6afa279b:0x8e72f1ab8357a0de?ved=2ahUKEwjszqKBrebfAhVENX0KHRYxD0EQ8gEwAHoECAAQAQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              Unit 610 - 550 West Broadway
              <br />
              Vancouver, BC V5Z 0E9
            </a>
            <br />
            <a
              href="tel:1-604-559-8383"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tel: 604-559-8383
            </a>
          </Contact>
          <MobileButtonWrapper>
            <Button link="/contact-us" label="Contact Us" />
          </MobileButtonWrapper>
          <Copyright>
            <p>&#169; Copyright Enera Enterprises Ltd. 2018. </p>
            <Link to="/terms">Terms of Use and Privacy Policy</Link>
          </Copyright>
        </Left>
        <ButtonWrapper>
          <Button link="/contact-us" label="Contact Us" />
        </ButtonWrapper>
      </StyledContainer>
    </Root>
  );
};

export default Footer;

const Root = styled.div`
  /* background: #00205c; */
  background: url(${require('src/assets/images/bg-shapes/footer/desktop.jpg')});
  background-size: cover;
  height: ${vw(576)};
  width: 100%;
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  z-index: -1;
  @media (max-width: ${media.sm}) {
    height: ${vwTablet(425)};
    background-image: url(${require('src/assets/images/bg-shapes/footer/tablet.jpg')});
  }
  @media (max-width: ${media.xs}) {
    background-image: url(${require('src/assets/images/bg-shapes/footer/mobile.jpg')});
    height: ${vwMobile(381)};
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }
`;

const StyledContainer = styled(Container)`
  margin-top: ${vw(80)};
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (max-width: ${media.sm}) {
    margin-top: ${vwTablet(80)};
    margin-left: 16.67%;
  }
  @media (max-width: ${media.xs}) {
    margin: 0 auto;
    justify-content: center;
    position: unset;
  }
`;

const WordmarkWrapper = styled.div`
  width: ${vw(171)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: ${vw(80)};
  @media (max-width: ${media.sm}) {
    width: ${vwTablet(155)};
    margin-top: ${vwTablet(20)};
    margin-bottom: ${vwTablet(20)};
  }
  @media (max-width: ${media.xs}) {
    width: ${vwMobile(100)};
    margin-top: 0;
    margin-bottom: ${vwMobile(40)};
    text-align: center;
    align-items: center;
  }

  img {
    width: 100%;
    height: auto;
  &:nth-child(2) {
    width: 66%;
    margin-top: ${vw(10)};
    @media (max-width: ${media.sm}) {
      margin-top: ${vwTablet(10)};
    }
    @media (max-width: ${media.xs}) {
      margin-top: ${vwMobile(10)};
    }
  }
}
`;

const Left = styled.div`
  height: 100%;

  @media (max-width: ${media.xs}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Contact = styled.div`
  a {
    color: white;
    opacity: 0.8;
    font-size: ${vw(18)};
    line-height: ${vw(32)};
    position: relative;
    transition: color 0.4s ease-out;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(24)};
      text-align: center;
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(18)};
      text-align: center;
    }
    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      border-bottom: 1px solid ${({ theme }) => theme.colorOrange};
      width: 0;
      height: 1px;
      transition: width 0.4s ease-out;
    }
    &:hover {
      color: ${({ theme }) => theme.colorOrange};
      &::before {
        width: 100%;
      }
    }
  }
`;

const LogoWrapper = styled.a`
  position: absolute;
  top: 30px;
  left: 0;
  transform: translate3d(0, -50%, 0);
  padding-left: ${vw(50)};
  margin-top: ${vw(80)};
  width: 9.09%;
  @media (max-width: ${media.sm}) {
    width: 12.5%;
    margin-top: ${vwTablet(80)};
    margin-bottom: ${vwTablet(20)};
  }
  @media (max-width: ${media.xs}) {
    position: relative;
    top: ${vwMobile(20)};
    width: 100%;
    width: ${vwMobile(35)};
    height: auto;
    margin-top: ${vwMobile(20)};
    margin-bottom: ${vwMobile(20)};
    padding-left: 0;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

const Copyright = styled.div`
  color: ${({ theme }) => theme.colorGray};
  position: absolute;
  bottom: ${vw(40)};
  left: 0;
  
  @media (max-width: ${media.sm}) {
    width: 100%;
    text-align: center;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  @media (max-width: ${media.xs}) {
    width: 100%;
    text-align: center;
    bottom: ${vwMobile(20)};
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  p {
    display: inline;
    font-size: ${vw(12)};
    line-height: ${vw(21)};
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(14)};
      line-height: ${vwTablet(21)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(12)};
      line-height: ${vwMobile(21)};
      display: block;
    }
  }
  a {
    color: ${({ theme }) => theme.colorGray};
    text-decoration: underline;
    font-size: ${vw(12)};
    line-height: ${vw(21)};
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(14)};
      line-height: ${vwTablet(21)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(12)};
      line-height: ${vwMobile(21)};
      display: block;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: block;
  @media (max-width: ${media.sm}) {
    margin-right: 8.03%;
  }
  @media (max-width: ${media.xs}) {
    display: none;
  }
`;

const MobileButtonWrapper = styled.div`
  display: none;
  @media (max-width: ${media.xs}) {
    display: block;
    margin: ${vwMobile(40)} 0;
  }
`;
