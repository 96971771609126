import React, { PureComponent } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { Parallax, withController } from 'react-scroll-parallax';

import Page from 'components/Page';
import LastSection from 'components/LastSection';
import Spacing from 'components/Spacing';
import Container from 'components/Container';
import Form from './elements/Form/Form';
import Facebook from './elements/Icons/Facebook';
import Instagram from './elements/Icons/Instagram';
// import Line from './elements/Icons/Line';
// import Wechat from './elements/Icons/Wechat';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

class Contact extends PureComponent {
  handleLoad = () => {
    // updates cached values after image dimensions have loaded
    this.props.parallaxController.update();
  };

  render() {
    return (
      <Root>
        <Page>
          <BGShapeTop>
            <Parallax
              y={[-20, 1]}
            >
              <img
                src={require('src/assets/images/contact/bg-shapes/shape_1.png')}
                alt="bg-shape"
                onLoad={this.handleLoad}
              />
            </Parallax>
          </BGShapeTop>
          <BGShapeMiddle>
            <Parallax
              y={[-10, 30]}
            >
              <img
                src={require('src/assets/images/contact/bg-shapes/shape_2.png')}
                alt="bg-shape"
                onLoad={this.handleLoad}
              />
            </Parallax>
          </BGShapeMiddle>
          <BGShapeBottom>
            <Parallax
              y={[-50, 10]}
            >
              <img
                src={require('src/assets/images/contact/bg-shapes/shape_3.png')}
                alt="bg-shape"
                onLoad={this.handleLoad}
              />
            </Parallax>
          </BGShapeBottom>
        </Page>

        <Spacing value={vw(205)} />
        <LastSection>
          <Container>
            <h1>Get in Touch</h1>
            <Row>
              <Left>
                <h4>Address</h4>
                <a
                  href="https://www.google.com/maps/place/550+W+Broadway,+Vancouver,+BC+V5Z+0A9/@49.2629456,-123.1189015,17z/data=!3m1!4b1!4m5!3m4!1s0x548673dd6afa279b:0x8e72f1ab8357a0de!8m2!3d49.2629456!4d-123.1167128"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Unit 610 – 550 West Broadway Vancouver, BC V5Z 0E9
                </a>
              </Left>
              <Middle>
                <h4>Office Hours</h4>
                <p>
                  Monday to Friday
                  <br />
                  9:00 AM to 5:00 PM
                </p>
                <h4>Phone Number</h4>
                <p>
                  <a
                    href="tel:+1-604-559-8383"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    604-559-8383
                  </a>
                </p>
                <h4>Fax</h4>
                <p>
                  <a
                    href="tel:+1-604-559-8322"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    604-559-8322
                  </a>
                </p>
                <h4>Social</h4>
                <SocialIcons>
                  <a
                    href="https://www.facebook.com/Enera-Enterprises-Ltd-1276865815771232/?modal=admin_todo_tour"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook />
                  </a>
                  <a
                    href="https://www.instagram.com/eneraenterprises/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Instagram />
                  </a>
                  {/* <a href="/">
                    <Line />
                  </a>
                  <a href="/">
                    <Wechat />
                  </a> */}
                  {/* <a href="/"><img src={require('src/assets/images/contact/social-icons/instagram.svg')} /></a>
                  <a href="/"><img src={require('src/assets/images/contact/social-icons/line.svg')} /></a>
                  <a href="/"><img src={require('src/assets/images/contact/social-icons/wechat.svg')} /></a> */}
                </SocialIcons>
              </Middle>
              <TopRight>
                <h4>Email</h4>
                <p>
                  <a
                    href="mailto:contact@enera.ca"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    contact@enera.ca
                  </a>
                </p>
                <h4>Careers</h4>
                <p>
                  <a
                    href="mailto:careers@enera.ca"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    careers@enera.ca
                  </a>
                </p>
                <h4>Investment Opportunities</h4>
                <p>
                  <a
                    href="mailto:partnerships@enera.ca"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    partnerships@enera.ca
                  </a>
                </p>
              </TopRight>
            </Row>
            <Row>
              <LeftBottom>
                <p>Please fill out the form and we will be in touch soon. </p>
              </LeftBottom>
              <BottomRight>
                <Form />
              </BottomRight>
            </Row>
          </Container>
        </LastSection>
      </Root>
    );
  }
}

export default withController(Contact);

const Root = styled.div`
  background: #547dbf;
  min-height: 69vh;
  position: relative;

  h1 {
    font-family: ${({ theme }) => theme.fontHeader};
    font-size: ${vw(50)};
    font-weight: bold;
    line-height: ${vw(50)};
    letter-spacing: ${vw(1)};
    color: white;
    position: relative;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(42)};
      line-height: ${vwTablet(50)};
      letter-spacing: ${vwTablet(0.84)};
      padding-top: ${vwTablet(60)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(30)};
      line-height: ${vwMobile(36)};
      letter-spacing: ${vwMobile(0.5)};
      padding-top: ${vwMobile(60)};
    }
  }
`;

const Row = styled.div`
  margin-top: ${vw(60)};
  display: flex;

  @media (max-width: ${media.sm}) {
    flex-wrap: wrap;
  }
  h4 {
    opacity: 0.8;
    font-family: ${({ theme }) => theme.fontBody};
    font-size: ${vw(13)};
    line-height: ${vw(13)};
    letter-spacing: ${vw(1.5)};
    color: white;
    text-transform: uppercase;
    margin-bottom: ${vw(5)};
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(13)};
      line-height: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1.5)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(12)};
      line-height: ${vwMobile(12)};
      letter-spacing: ${vwMobile(1)};
    }
  }
  a,
  p {
    font-family: ${({ theme }) => theme.fontBody};
    font-size: ${vw(20)};
    line-height: ${vw(30)};
    letter-spacing: ${vw(0.5)};
    color: white;
    margin-bottom: ${vw(20)};
    position: relative;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(18)};
      line-height: ${vwTablet(28)};
      letter-spacing: ${vwTablet(0.5)};
      margin-bottom: ${vwTablet(20)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(18)};
      letter-spacing: 0;
      margin-bottom: ${vwMobile(20)};
    }
  }
  a {
    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      border-bottom: 1px solid ${({ theme }) => theme.colorOrange};
      width: 0;
      height: 1px;
      transition: width 0.4s ease-out;
    }
    &:hover {
      &::before {
        width: 100%;
      }
    }
  }
`;

const Left = styled.div`
  width: 27%;
  z-index: 1;
  @media (max-width: ${media.sm}) {
    width: 50%;
    margin-right: 50%;
    margin-bottom: ${vwTablet(40)};
  }
  @media (max-width: ${media.xs}) {
    width: 100%;
    margin-bottom: ${vwMobile(20)};
  }
`;

const LeftBottom = styled.div`
  width: 27%;
  z-index: 1;
  @media (max-width: ${media.sm}) {
    width: 100%;
    margin-bottom: 0;
  }
`;

const Middle = styled.div`
  width: 18%;
  margin-left: 9%;
  z-index: 1;
  @media (max-width: ${media.sm}) {
    width: 50%;
    margin-left: 0;
  }
  @media (max-width: ${media.xs}) {
    width: 100%;
    margin-bottom: 0;
  }
`;

const TopRight = styled.div`
  margin-left: 9%;
  width: 27%;
  z-index: 1;
  @media (max-width: ${media.sm}) {
    width: 50%;
    margin-left: 0;
  }
  @media (max-width: ${media.xs}) {
    width: 100%;
    margin-bottom: 0;
  }
`;

const BottomRight = styled.div`
  margin-left: 9%;
  width: 45%;
  padding-bottom: ${vw(120)};
  z-index: 1;
  @media (max-width: ${media.sm}) {
    width: 81.81%;
    margin-left: 0;
  }
  @media (max-width: ${media.xs}) {
    width: 100%;
    margin-left: 0;
  }
`;

const BGShapeTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  pointer-events: none;
  & img {
    width: 100%;
    height: auto;
  }
`;

const BGShapeMiddle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 0;
  pointer-events: none;

  & img {
    width: 100%;
    height: auto;
  }
`;

const BGShapeBottom = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 0;
  pointer-events: none;

  & img {
    width: 100%;
    height: auto;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 90%;
  height: auto;
  margin-top: ${vw(20)};
  @media (max-width: ${media.sm}) {
    margin-top: ${vwTablet(20)};
    width: 70%;
  }
  @media (max-width: ${media.xs}) {
    margin-top: ${vwMobile(20)};
  }

  & a {
    &::before {
      content: '';
      border-bottom: none;
    }
    &:hover {
      &::before {
        width: 100%;
      }
    }
  }

  & img {
    width: 80%;
    height: auto;
  }
  svg {
    padding-right: 20px;
  }
`;
