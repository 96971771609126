import React from 'react';

import InvestProject from '../elements/InvestProject';

const Fraser = () => {
  return (
    <InvestProject
      name="6204 Fraser St."
      location="Vancouver, BC"
      type="2-Storey/10,786 Sq. Ft. Rentable Space"
      size="11,385 Sq. Ft."
      lease="Long-term CIBC lease"
      image={require('src/assets/images/investments/4.jpg')}
    />
  );
};

export default Fraser;