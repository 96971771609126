import React, { PureComponent } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { Parallax, withController } from 'react-scroll-parallax';

import HeroCard from '../elements/HeroCard';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import imgWordmark from 'src/assets/images/logo/wordmark-dark.svg';
import imgWordmark2 from 'src/assets/images/logo/wordmark-dark-2.svg';

class Landing extends PureComponent {
  handleLoad = () => {
    // updates cached values after image dimensions have loaded
    this.props.parallaxController.update();
  };
  
  render() {
    return (
      <Root>
        <BGShapeTop>
          <Parallax
            y={[-20, 20]}
            tagOuter="figure"
          >
            <img
              src={require('src/assets/images/home/bg-shapes/shape_1.png')}
              alt="bg-shape"
              onLoad={this.handleLoad}
            />
          </Parallax>
        </BGShapeTop>
        <BGShapeBottom>
          <Parallax
            y={[-70, 70]}
            tagOuter="figure"
          >
            <img
              src={require('src/assets/images/home/bg-shapes/shape_2.png')}
              alt="bg-shape"
              onLoad={this.handleLoad}
            />
          </Parallax>
        </BGShapeBottom>
        <Branding>
          <Text id="landing">
            <WordmarkWrapper>
              <img src={imgWordmark} alt="Enera" onLoad={this.handleLoad}/>
              <img src={imgWordmark2} alt="Enterprises" onLoad={this.handleLoad}/>
            </WordmarkWrapper>
            <p>
              Creativity, innovation and practicality are our cornerstones, and
              a foundation that redefines the future of real estate development
              and investment
            </p>
          </Text>
        </Branding>
        <SectionCard>
          <HeroCard
            label="communities"
            text="Investing in prime revenue generating properties. "
            to="/communities"
            image={require('src/assets/images/home/communities.jpg')}
            imageTablet={require('src/assets/images/home/tablet_communities.jpg')}
          />
          <HeroCard
            label="investments"
            text="Experienced real estate developers"
            to="/investments"
            image={require('src/assets/images/home/investments.jpg')}
            imageTablet={require('src/assets/images/home/tablet_investments.jpg')}
          />
        </SectionCard>
      </Root>
    );
  }
}

const Root = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background: #7aa4dd;
  position: relative;
  overflow: hidden;
  @media (max-width: ${media.sm}) {
    flex-direction: column;
    height: 100%;
  }
`;

const BGShapeTop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  height: auto;
  z-index: 1;
  pointer-events: none;
  @media (max-width: ${media.sm}) {
    display: none;
  }
  & img {
    width: 100%;
    height: auto;
  }
`;

const BGShapeBottom = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  @media (max-width: ${media.sm}) {
    display: none;
  }
  & img {
    width: 100%;
    height: auto;
  }
`;

const Branding = styled.div`
  width: 50%;
  height: 100%;
  margin-left: 3.34%;
  background: url(${require('src/assets/images/home/herobanner_background.jpg')});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  @media (max-width: ${media.sm}) {
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${media.xs}) {
    width: 100%;
    height: 100%;
    padding-top: ${vwMobile(100)};
    padding-bottom: ${vwMobile(54)};
  }
`;

const Text = styled.div`
  width: 66.67%;
  margin-left: 33.33%;
  margin-right: 15%;
  /* width: 50%;
  margin-left: 50%;
  transform: translate3d(-50%, 0, 0); */
  p {
    font-family: ${({ theme }) => theme.fontBody};
    font-size: ${vw(20)};
    line-height: ${vw(30)};
    letter-spacing: ${vw(0.5)};
    color: white;
  }
  @media (max-width: ${media.sm}) {
    width: 58.33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50vh;
    p {
      font-size: ${vwTablet(18)};
      line-height: ${vwTablet(28)};
      letter-spacing: ${vwTablet(0.5)};
    }
  }
  @media (max-width: ${media.xs}) {
    width: 66.67%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      font-size: ${vwMobile(13)};
      line-height: ${vwMobile(20)};
      letter-spacing: ${vwMobile(0)};
    }
  }
`;

const WordmarkWrapper = styled.div`
  margin-bottom: ${vw(40)};
  img {
    width: 100%;
    max-width: fit-content;
    height: auto;
    &:first-child {
      margin-bottom: ${vw(20)};
      display: block;
    }
  }
  @media (max-width: ${media.sm}) {
    margin-bottom: ${vwTablet(31)};
    img:first-child {
      margin-bottom: ${vwTablet(22)};
    }
  }
`;

const SectionCard = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  @media (max-width: ${media.sm}) {
    width: 100%;
    flex-direction: column;
  }
`;

export default withController(Landing);