import React from 'react'
import styled from 'styled-components';

const ColumnTwo = ({ left, right }) => {
  return (
    <Root>
      <Column>{left}</Column>
      <Column>{right}</Column>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  width: 100%;
`;

const Column = styled.div`
  width: 50%;
  z-index: 1;
`;

export default ColumnTwo;