import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const Description = ({ text, customPadding }) => {
  return (
    <Root customPadding={customPadding}>
      <h1>{text.title}</h1>
      <Content>
        <MainText>{text.body}</MainText>
        <Subtext>{text.subtext}</Subtext>
      </Content>
      <Tagline>{text.tagline}</Tagline>
    </Root>
  );
};

export default Description;

const Root = styled.div`
  color: white;
  position: relative;
  margin-top: ${props => props.customPadding ? `${vw(props.customPadding)}` : `${vw(205)}`};
  padding-bottom: ${vw(147)};
  @media (max-width: ${media.sm}) {
    margin-top: ${vwTablet(120)};
    padding-bottom: ${vwTablet(67)};
  }
  @media (max-width: ${media.xs}) {
    margin-top: ${vwMobile(71)};
    padding-bottom: ${vwMobile(40)};
  }
  h1 {
    font-family: ${({ theme }) => theme.fontHeader};
    font-size: ${vw(50)};
    line-height: ${vw(50)};
    letter-spacing: ${vw(1)};
    margin-bottom: ${vw(40)};
    @media (max-width: ${media.sm}) {
      margin-bottom: ${vwTablet(30)};
      font-size: ${vwTablet(41)};
      font-weight: bold;
      line-height: ${vwTablet(50)};
      letter-spacing: ${vwTablet(0.8)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(30)};
      font-weight: bold;
      line-height: ${vwMobile(36)};
      letter-spacing: ${vwMobile(0.5)};
    }
  }
`;

const Content = styled.div`
  width: ${vw(750)};
  @media (max-width: ${media.sm}) {
    width: 81.81%;
  }
  @media (max-width: ${media.xs}) {
    width: 91.6%;
  }
`;

const MainText = styled.p`
  color: white;
  font-size: ${vw(20)};
  line-height: ${vw(30)};
  letter-spacing: ${vw(0.5)};
  @media (max-width: ${media.sm}) {
    font-size: ${vwTablet(18)};
    line-height: ${vwTablet(28)};
    letter-spacing: ${vwTablet(0.5)};
  }
  @media (max-width: ${media.xs}) {
    font-size: ${vwMobile(14)};
    line-height: ${vwMobile(20)};
    letter-spacing: 0;
  }
`;

const Subtext = styled.p`
  font-family: ${({ theme }) => theme.fontBody};
  font-size: ${vw(16)};
  line-height: ${vw(26)};
  opacity: 0.8;
  @media (max-width: ${media.sm}) {
    font-size: ${vwTablet(16)};
    line-height: ${vwTablet(26)};
  }
  @media (max-width: ${media.xs}) {
    font-size: ${vwMobile(14)};
    line-height: ${vwMobile(18)};
    letter-spacing: 0;
  }
`;

const Tagline = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(90deg) translate3d(100%, 0, 0);
  transform-origin: top right;
  font-size: ${vw(16)};
  letter-spacing: ${vw(3)};
  line-height: ${vw(16)};
  font-family: ${({ theme }) => theme.fontBody};
  font-weight: 300;
  text-transform: uppercase;
  opacity: 0.4;
  @media (max-width: ${media.sm}) {
    font-size: ${vwTablet(16)};
    font-weight: 300;
    line-height: ${vwTablet(16)};
    letter-spacing: ${vwTablet(3)};
  }
  @media (max-width: ${media.xs}) {
    font-size: ${vwMobile(11)};
    line-height: ${vwMobile(16)};
    letter-spacing: ${vwMobile(2.06)};
  }
`;
