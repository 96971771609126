import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { hot } from 'react-hot-loader';

import { prerenderStyles } from 'src/plugins/prerender';
import routes from 'src/routes';

// import Header from 'components/Header';
import Footer from 'components/Footer';

class App extends Component {
  componentDidMount() {
    prerenderStyles();
    new window.GambitSmoothScroll({
      amount: 150,
      speed: 1800,
    });
  }

  generateRoutes() {
    return routes.map((route, index) => {
      const { path, component } = route;
      const Component = require(`src/${component}`).default;
      return <Route exact path={path} component={Component} key={index} />;
    });
  }

  render() {
    return (
      <Route
        render={({ location }) => (
            <AppWrapper>
              {/* <Header /> */}
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={500}
                  classNames="fade"
                >
                  <Switch location={location}>{this.generateRoutes()}</Switch>
                </CSSTransition>
              </TransitionGroup>
              <Footer />
            </AppWrapper>
        )}
      />
    );
  }
}

const AppWrapper = styled.div`
  background: #7aa4dd;
  .fade-enter {
    opacity: 0;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 0.5s;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: all 0.5s;
  }
`;

export default hot(module)(App);
