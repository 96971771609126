import React from 'react'
import styled from 'styled-components';
import media from 'src/styles/media';

const ContentColumnTwo = ({ left, right, breakpoint, mobileReverse }) => {
  return (
    <Root breakpoint={breakpoint} mobileReverse={mobileReverse}>
      <Left breakpoint={breakpoint}>{left}</Left>
      <Right breakpoint={breakpoint}>{right}</Right>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  z-index: 1;
  @media (max-width: ${props => props.breakpoint}) {
    flex-direction: ${props => props.mobileReverse ? 'column-reverse': 'column'};
  }
`;

const Left = styled.div`
  width: 45.45%;
  @media (max-width: ${props => props.breakpoint}) {
    width: 100%;
  }
`;

const Right = styled.div`
  margin-left: 9.05%;
  width: 45.45%;
  @media (max-width: ${media.sm}) {
    width: 50%;
    margin-left: 4.55%;
  }

  @media (max-width: ${props => props.breakpoint}) {
    width: 100%;
    margin-left: 0;
  }
`;

export default ContentColumnTwo;