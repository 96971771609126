import React from 'react';
import styled from 'styled-components';

import TextBlock from 'components/TextBlock';

const ProjectCard = ({ project, type }) => {
  return type === 'communities' ? (
    <Root>
      <Carousel />
      <TextBlockWrapper>
        <TextBlock type="project" text={project.text} />
      </TextBlockWrapper>
    </Root>
  ) : (
    <RootInvestment>
      <Image background={project.image} />
      <TextBlockWrapper>
        <TextBlock type="investment" text={project.text} />
      </TextBlockWrapper>
    </RootInvestment>
  );
};

export default ProjectCard;

const Root = styled.div`
  height: 770px;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Carousel = styled.div`
  background: skyblue;
  width: 75%;
  height: 100%;
`;

const TextBlockWrapper = styled.div`
  background: darkcyan;
  flex: 1;
  height: 100%;
  padding: 60px 48px;

  background-image: linear-gradient(0deg, #002e72 0%, #00205c 50%);
`;

const Image = styled.div``;

const RootInvestment = styled.div`
  height: 488px;
  width: 100%;
  display: flex;
  flex-direction: row;
  > div {
    width: 50%;
    display: flex;
  }

  :nth-child(even) {
    flex-direction: row-reverse;
  }
`;
