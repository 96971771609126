import React from 'react';

import InvestProject from '../elements/InvestProject';

const BridgePort = () => {
  return (
    <InvestProject
      name="12111 Bridegport Rd."
      location="Richmond, BC"
      type="40,752 Sq. Ft. Rentable Space"
      size="1.5 Acres"
      lease="Fully Leased"
      image={require('src/assets/images/investments/1.jpg')}
      isDesktopReverse
    />
  );
};

export default BridgePort;