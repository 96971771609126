import React, { Component } from 'react';
import styled from 'styled-components';
import Waypoint from 'react-waypoint';

import Logo from 'components/Logo';

class LayoutNoHide extends Component {
  state = {
    unstuck: false,
  };

  _handleWaypointEnter = () => {
    this.setState({ unstuck: true });
  };

  _handleWaypointLeave = () => {
    this.setState({ unstuck: false });
  };

  render() {
    return (
      <Root unstuck={this.state.unstuck}>
        <ContentWithBackground>
          <LogoWrapper unstuck={this.state.unstuck}>
            <Logo />
          </LogoWrapper>
          <ContentUnderLogo>{this.props.children}</ContentUnderLogo>
        </ContentWithBackground>
        <Offset />
        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
          // topOffset={200}
          // bottomOffset={200}
        />
      </Root>
    );
  }
}

export default LayoutNoHide;

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${props => (props.unstuck ? '-1' : 'unset')};
`;

const LogoWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  padding-top: 40px;
  padding-left: 50px;
  z-index: 999;
  width: 200px;
  opacity: ${props => (props.unstuck ? '0' : '1')};
  transform: ${props => (props.unstuck ? 'translateY(50px)' : 'translateY(0)')};
  transition: all 0.8s;
`;

const ContentUnderLogo = styled.div`
  background: ${({ theme }) => theme.colorWhite};
  padding-left: 190px;
  padding-top: 95px;
  padding-bottom: 154px;

  h1 {
    font-family: ${({ theme }) => theme.fontHeader};
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 50px;
    color: white;
    margin-bottom: 55px;
  }

  p {
    max-width: 513px;
    font-family: ${({ theme }) => theme.fontBody};
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: white;
  }
`;

const Offset = styled.div`
  height: 648px;
  pointer-events: none;
`;

const ContentWithBackground = styled.div`
  background: ${({ theme }) => theme.colorLightBlue};
`;
