import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

class Icon extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    isOpen: false,
    onClick: f => f,
  };

  handleClick = () => {
    this.props.onClick();
  };

  render() {
    return (
      <Root
        open={this.props.isOpen}
        onClick={this.handleClick}
        className={this.props.isOpen ? 'close' : ''}
      >
        <span />
        <span />
        <span />
        <span />
      </Root>
    );
  }
}

const Root = styled.div`
  width: ${vw(24)};
  height: ${vw(20)};
  position: fixed;
  right: 20px;
  top: ${vw(33)};
  /* transform: translateY(-50%); */
  transition: all 0.5s;
  display: none;
  @media (max-width: ${media.sm}) {
    display: block;
    z-index: 101;
    width: ${vwTablet(24)};
    height: ${vwTablet(20)};
    right: 4.5%;
    top: ${vwTablet(36)};
  }
  @media (max-width: ${media.xs}) {
    display: block;
    z-index: 101;
    width: ${vwMobile(24)};
    height: ${vwMobile(20)};
    right: 20px;
    top: ${vwMobile(24)};
  }
  span {
    position: absolute;
    width: 100%;
    background: white;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.15s;
    &:nth-child(1) {
      top: 0;
      transform: translateX(-50%) scaleX(1);
    }
    &:nth-child(2) {
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &:nth-child(3) {
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &:nth-child(4) {
      bottom: 0;
      transform: translateX(-50%) scaleX(1);
    }
  }
  &.close {
    span {
      &:nth-child(1) {
        top: 0;
        transform: translateX(-50%) scaleX(0);
      }
      &:nth-child(2) {
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:nth-child(3) {
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &:nth-child(4) {
        bottom: 0;
        transform: translateX(-50%) scaleX(0);
      }
    }
  }
`;

export default Icon;
