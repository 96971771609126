import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import ContentColumnTwo from 'components/ContentColumnTwo';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const Leadership = () => {
  return (
    <Root>
      <TabletTitle>Leadership</TabletTitle>
      <ContentColumnTwo
        left={<Image src={require('src/assets/images/about/leadership.jpg')} />}
        right={
          <TextWrapper>
            <h2>Leadership</h2>
            <p>
              Our executive management of Enera Enterprises has over 20 years of
              real estate investment and development experience. Under this
              leadership, Enera has grown its portfolio of prime
              revenue-generating properties to comprise of investments
              throughout Western Canada as well as in Manhattan, New York.
              <br />
              <br />
              Focussed on sustainable growth, the leadership and management
              structure of Enera is poised to continue targeting new
              opportunities as it adds to its existing portfolio of real estate
              investments and developments.
            </p>
          </TextWrapper>
        }
        breakpoint={media.xs}
        mobileReverse
      />
    </Root>
  );
};

export default Leadership;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  /* background: ${({ theme }) => theme.colorDarkBlue}; */
  /* padding-left: 190px; */
  @media (max-width: ${media.sm}) {
    padding-bottom: ${vwTablet(60)};
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;
const TextWrapper = styled.div`
  @media (max-width: ${media.xs}) {
    margin-bottom: ${vwMobile(20)};
  }
  h2 {
    font-family: ${({ theme }) => theme.fontHeader};
    font-size: ${vw(38)};
    line-height: ${vw(32)};
    margin-bottom: ${vw(40)};
    font-weight: normal;
    z-index: 1;
    @media (max-width: ${media.sm}) {
      display: none;
    }
  }
  p {
    font-family: ${({ theme }) => theme.fontBody};
    font-size: ${vw(16)};
    line-height: ${vw(26)};
    opacity: 0.8;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(26)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(18)};
    }
  }
`;

const TabletTitle = styled.h2`
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: ${vw(38)};
  line-height: ${vw(32)};
  margin-bottom: ${vw(40)};
  font-weight: normal;
  z-index: 1;
  display: none;
  @media (max-width: ${media.sm}) {
    font-size: ${vwTablet(36)};
    line-height: ${vwTablet(36)};
    margin-bottom: ${vwTablet(26)};
    display: block;
  }
  @media (max-width: ${media.xs}) {
    font-size: ${vwMobile(24)};
    line-height: ${vwMobile(32)};
    margin-bottom: ${vwMobile(26)};
    display: block;
  }
`;
