import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Parallax, withController } from 'react-scroll-parallax';

import Page from 'components/Page';
import LastSection from 'components/LastSection';
import Container from 'components/Container';
import Description from 'components/Description';
import Leadership from './sections/Leadership';
import Philosophy from './sections/Philosophy';
import InvestmentOpportunities from './sections/InvestmentOpportunities';

const text = {
  title: 'About',
  body:
    'Headquartered in Vancouver, Canada, Enera Enterprises specializes in commercial and residential real estate acquisition, management, and development. We were founded in 2007 on the principles of building an enduring investment portfolio and developing projects through sustainable practices that benefit the long-term well-being of the communities',
  subtext: (
    <span>
      <br />
      Through a conservative approach to investment and development, we identify
      and invest in long-term opportunities that embody the potential for solid
      returns, future growth, and re-development possibilities.
      <br />
      <br />
      Along with striving to discover and invest in long-term opportunities with
      stable earnings and the prospect of future growth, we are guided by a core
      belief that the resources we commit to our projects must be targeted to
      ensure they achieve our goals in ethical and sustainable ways.
    </span>
  ),
  tagline: 'Focussed on Sustainable Growth',
};

class About extends PureComponent {
  handleLoad = () => {
    // updates cached values after image dimensions have loaded
    this.props.parallaxController.update();
  };

  render() {
    return (
      <Root>
        <BGShapeTop>
          <Parallax y={[-20, 5]}>
            <img
              src={require('src/assets/images/about/bg-shapes/shape_1.png')}
              alt="bg-shape"
              onLoad={this.handleLoad}
            />
          </Parallax>
        </BGShapeTop>
        <BGShapeBottom>
          <Parallax y={[-100, 10]}>
            <img
              src={require('src/assets/images/about/bg-shapes/shape_2.png')}
              alt="bg-shape"
              onLoad={this.handleLoad}
            />
          </Parallax>
        </BGShapeBottom>
        <Page customPadding={-80}>
          <Container>
            <Description text={text} customPadding={100} />
            <Leadership />
          </Container>
        </Page>
        <LastSection>
          <Philosophy />
          <InvestmentOpportunities />
        </LastSection>
      </Root>
    );
  }
}

export default withController(About);

const Root = styled.div`
  background-color: #00205c;
`;

const BGShapeTop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  & img {
    width: 100%;
    height: auto;
  }
`;

const BGShapeBottom = styled.div`
  position: absolute;
  top: 0%;
  right: 0;
  width: 100%;
  height: auto;
  & img {
    width: 100%;
    height: auto;
  }
`;
