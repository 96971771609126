import React from 'react';

import InvestProject from '../elements/InvestProject';

const NewYork = () => {
  return (
    <InvestProject
      name="New York"
      location="Upper East Side, NY"
      type="Residential"
      lease="Long-term"
      image={require('src/assets/images/investments/3.jpg')}
      isDesktopReverse
    />
  );
};

export default NewYork;
