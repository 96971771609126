import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import imgLogo from 'src/assets/images/logo/e_logo.svg';

const Logo = () => {
  return (
    <Link to="/">
      <LogoImg src={imgLogo} alt="Logo" />
    </Link>
  );
};

const LogoImg = styled.img``;

export default Logo;
