import React from 'react';
import Reveal from 'react-reveal/Reveal';
import PropTypes from 'prop-types';

const ScrollAnimation = ({ children, effect, effectOut }) => {
  return <Reveal effect={effect} effectOut="fadeOut">{children}</Reveal>;
};

ScrollAnimation.propTypes = {
  effect: PropTypes.string,
  effectOut: PropTypes.string
}

ScrollAnimation.defaultProps = {
  effect: 'fadeInUp',
  effectOut: 'fadeOut'
}

export default ScrollAnimation;
