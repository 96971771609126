import React, { Component } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { OnUpdate } from 'rrc';
import media from 'src/styles/media';

import Container from 'components/Container';
import Icon from './elements/Icon';
import NavigationMobile from './elements/NavigationMobile';

import { vw } from 'src/styles/utils';

const links = [
  {
    id: 'About',
    url: '/about',
  },
  {
    id: 'Communities',
    url: '/communities',
  },
  {
    id: 'Investments',
    url: '/investments',
  },
  {
    id: 'Careers',
    url: '/careers',
  },
  {
    id: 'News',
    url: '/news',
  },
  {
    id: 'Contact Us',
    url: '/contact-us',
  },
];

export default class Header extends Component {
  state = {
    isMobileMenuOpen: false,
    isShowHeadline: true,
    firstLoad: window.location.pathname === '/',
  };

  constructor(props) {
    super(props);
    this.$node = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({ firstLoad: false });
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleClickMenu = () => {
    this.setState(state => ({ isMobileMenuOpen: !state.isMobileMenuOpen }));
  };

  handleCloseHeadline = () => {
    this.props.closeHeadline();
  };

  handleRouteChange = location => {
    window.addEventListener('scroll', this.handleScroll);
    this.setState({
      isMobileMenuOpen: false,
      firstLoad: location.pathname === '/',
    });
  };

  render() {
    return (
      <Root {...this.props}>
        <StyledContainer>
          <DesktopNav>
            {links.map(link => (
              <NavLink activeClassName="active" to={link.url} key={link.id}>
                {link.id}
              </NavLink>
            ))}
          </DesktopNav>
        </StyledContainer>
        <Icon
          onClick={this.handleClickMenu}
          isOpen={this.state.isMobileMenuOpen}
        />
        <NavigationMobile links={links} isOpen={this.state.isMobileMenuOpen} />
        <OnUpdate call={this.handleRouteChange} />
      </Root>
    );
  }
}

const Root = styled.header``;

const StyledContainer = styled(Container)`
  position: absolute;
  top: ${vw(70)};
  left: 0;
  z-index: 10;
`;

const DesktopNav = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontBody};
  line-height: ${vw(16)};
  letter-spacing: ${vw(1)};
  
  a {
    margin-right: ${vw(50)};
    color: white;
    transition: all 0.4s ease;
    &:last-of-type {
      margin-right: unset;
      margin-left: auto;
    }
    :hover {
      color: ${({ theme }) => theme.colorDarkBlue};
    }
  }
  .active {
    color: ${({ theme }) => theme.colorDarkBlue};
    font-weight: 600;
  }
  @media (max-width: ${media.sm}) {
    display: none;
  }
`;
