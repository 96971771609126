import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const Input = ({ label, name, required, pattern }) => {
  return (
    <Root>
      <label>{label}</label>
      <input type="text" name={name} required={required} pattern={pattern} />
    </Root>
  );
};

export default Input;

const Root = styled.div`
  background: white;
  width: 100%;
  height: ${vw(60)};
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontBody};
  padding: ${vw(5)} ${vw(10)};
  margin-bottom: ${vw(20)};
  @media (max-width: ${media.sm}) {
    height: ${vwTablet(60)};
    padding: ${vwTablet(5)} ${vwTablet(10)};
    margin-bottom: ${vwTablet(20)};
  }
  @media (max-width: ${media.xs}) {
    height: ${vwMobile(60)};
    padding: ${vwMobile(5)} ${vwMobile(10)};
    margin-bottom: ${vwMobile(20)};
  }

  input {
    width: 100%;
    height: 100%;
    font-size: ${vw(16)};
    color: ${({ theme }) => theme.colorDarkBlue};
    letter-spacing: ${vw(0.4)};
    line-height: ${vw(24)};
    border: none;
    outline: none;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(16)};
      letter-spacing: ${vwTablet(0.4)};
      line-height: ${vwTablet(24)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(16)};
      letter-spacing: ${vwMobile(0.4)};
      line-height: ${vwMobile(24)};
    }
  }
  label {
    font-size: ${vw(11)};
    font-weight: 300;
    color: ${({ theme }) => theme.colorDarkBlue};
    letter-spacing: ${vw(0.92)};
    line-height: ${vw(24)};
    text-transform: uppercase;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(0.92)};
      line-height: ${vwTablet(24)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(11)};
      letter-spacing: ${vwMobile(0.92)};
      line-height: ${vwMobile(24)};
    }
  }
`;
