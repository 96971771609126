import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw } from 'src/styles/utils';

const Container = props => {
  return (
    <Root {...props}>{props.children}</Root>
  );
};

const Root = styled.div`
  width: ${vw(1200)};
  margin: 0 auto;
  margin-left: 12%;
  /* padding-top: ${vw(120)}; */
  /* margin-top: ${vw(-46)}; */
  @media (max-width: ${media.xs}) {
    width: 92.5%;
    margin-left: 0;
    margin: 0 auto;
  }
`;

export default Container;
