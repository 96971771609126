import request from 'src/utils/request';
import appConfig from 'src/config/app.conf';

// Constants
export const NEWS__GET = 'NEWS::GET';

// Actions
export const getNews = () => dispatch => {
  request(
    `https://bam-cors-anywhere.herokuapp.com/${window.location.protocol}//${appConfig.wordpressAPIUrl}/posts?_embed=true`
  ).then(res => {
    dispatch({
      type: NEWS__GET,
      posts: res.data,
    });
  });
};

// Initial State
const initialState = {
  posts: [],
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case NEWS__GET:
      return {
        ...state,
        posts: action.posts,
      };
    default:
      return state;
  }
};
