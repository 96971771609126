import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import media from 'src/styles/media';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const silderSettings = {
  infinite: true,
  nextArrow: (
    <img
      src={require('src/assets/images/communities/arrow-right.svg')}
      alt="arrow"
    />
  ),
  prevArrow: (
    <img
      src={require('src/assets/images/communities/arrow-left.svg')}
      alt="arrow"
    />
  ),
};

const Project = ({
  name,
  detail,
  location,
  size,
  type,
  completion,
  websiteURL,
  sliders,
}) => {
  return (
    <Root>
      <SliderWrapper>
        <Slider {...silderSettings}>
          {sliders.map(slider => (
            <img src={slider} alt="slider" />
          ))}
        </Slider>
      </SliderWrapper>
      <Info>
        <Container>
          <Block>
            <h3>{name}</h3>
            <p>{detail}</p>
          </Block>
          <Block>
            <Item>
              <h4>Location</h4>
              <p>{location}</p>
            </Item>
            <Item>
              <h4>Size</h4>
              <p>{size}</p>
            </Item>
            <Item>
              <h4>Type</h4>
              <p>{type}</p>
            </Item>
            <Item>
              <h4>Completion</h4>
              <p>{completion}</p>
            </Item>

            {websiteURL && (
              <a href="/" target="_blank">
                Visit Website
              </a>
            )}
          </Block>
        </Container>
      </Info>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  background-color: #00205c;
  position: relative;
  z-index: 10;
  @media (max-width: ${media.sm}) {
    flex-direction: column-reverse;
  }
`;

const SliderWrapper = styled.div`
  width: 70%;
  position: relative;
  @media (max-width: ${media.sm}) {
    width: 100%;
  }
  .slick-prev {
    position: absolute;
    top: 50%;
    left: ${vw(29)};
    transform: translate3d(0, -50%, 0) scale(2);
    z-index: 1;
    @media (max-width: ${media.sm}) {
      left: ${vwTablet(29)};
    }
    @media (max-width: ${media.xs}) {
      left: ${vwMobile(29)};
    }
  }
  .slick-next {
    position: absolute;
    top: 50;
    right: ${vw(29)};
    transform: translate3d(0, -50%, 0) scale(2);
    @media (max-width: ${media.sm}) {
      right: ${vwTablet(29)};
    }
    @media (max-width: ${media.xs}) {
      right: ${vwMobile(29)};
    }
  }
`;

const Info = styled.div`
  width: 30%;
  background: ${({ theme }) => theme.colorDarkBlue};
  padding: ${vw(60)} ${vw(45)} ${vw(81)} ${vw(46)};
  position: relative;
  @media (max-width: ${media.sm}) {
    width: 100%;
  }
  @media (max-width: ${media.xs}) {
    width: 100%;
    padding: ${vwMobile(40)} ${vwMobile(12)};
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 95%, 0);
    background: url(${require('src/assets/images/communities/triangle.svg')});
    background-size: cover;
    width: 0;
    height: 0;
    @media (max-width: ${media.sm}) {
      width: ${vwTablet(60)};
      height: ${vwTablet(30)};
    }
    @media (max-width: ${media.xs}) {
      width: ${vwMobile(40)};
      height: ${vwMobile(20)};
    }
  }
`;

const Container = styled.div`
  @media (max-width: ${media.sm}) {
    /* width: ${vw(1200)}; */
    /* margin: ${vwTablet(60)} ${vwTablet(40)}; */
    margin-left: 12%;
    display: flex;
  }
  @media (max-width: ${media.xs}) {
    flex-direction: column;
    margin-left: 0;
    /* margin: ${vwMobile(12)} ${vwMobile(40)}; */
  }
`;

const Block = styled.div`
  margin-bottom: ${vw(40)};
  @media (max-width: ${media.sm}) {
    width: 50%;
    &:nth-child(2) {
      width: 40.91%;
      margin-left: 9.09%;
      margin-top: ${vwTablet(39.78)};
    }
  }
  @media (max-width: ${media.xs}) {
    width: 100%;
    &:nth-child(2) {
      width: 100%;
      margin-left: 0;
      display: grid;
      grid-template-columns: auto auto;
    }
  }

  h3 {
    font-family: ${({ theme }) => theme.fontBody};
    font-size: ${vw(20)};
    font-weight: 600;
    line-height: ${vw(32)};
    letter-spacing: ${vw(1.5)};
    color: white;
    text-transform: uppercase;
    margin-bottom: ${vw(20)};
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(18)};
      line-height: ${vwTablet(32)};
      letter-spacing: ${vwTablet(1.35)};
      margin-bottom: ${vwTablet(20)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(16)};
      line-height: ${vwMobile(24)};
      letter-spacing: ${vwMobile(0.5)};
      margin-bottom: ${vwMobile(20)};
    }
  }
  p {
    opacity: 0.8;
    font-family: ${({ theme }) => theme.fontBody};
    font-size: ${vw(16)};
    line-height: ${vw(26)};
    color: white;
    margin-bottom: ${vw(20)};
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(26)};
      margin-bottom: ${vwTablet(20)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(18)};
      margin-bottom: ${vwMobile(20)};
    }
  }
  h4 {
    font-family: ${({ theme }) => theme.fontBody};
    font-size: ${vw(13)};
    line-height: ${vw(13)};
    letter-spacing: ${vw(1.5)};
    color: ${({ theme }) => theme.colorGray};
    text-transform: uppercase;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(13)};
      line-height: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1.5)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(12)};
      line-height: ${vwMobile(12)};
      letter-spacing: ${vwMobile(1)};
    }
  }
  a {
    /* font need to be checked */
    font-family: ${({ theme }) => theme.fontBody};
    font-size: ${vw(14)};
    line-height: ${vw(21)};
    color: ${({ theme }) => theme.colorOrange};
    margin-top: ${vw(40)};
    position: relative;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(14)};
      line-height: ${vwTablet(21)};
      margin-top: ${vwTablet(40)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(21)};
      margin-top: 0;
    }
    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      border-bottom: 1px solid ${({ theme }) => theme.colorOrange};
      width: 0;
      height: 1px;
      transition: width 0.4s ease-out;
    }
    &:hover {
      &::before {
        width: 100%;
      }
    }
  }
`;

const Item = styled.div``;

export default Project;
