import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getNews } from 'src/redux/news';
import { findIndex } from 'lodash';
import dayjs from 'dayjs';

import Page from 'components/Page';
import Container from 'components/Container';
import LastSection from 'components/LastSection';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

class NewsSingle extends Component {
  componentDidMount() {
    if (!this.props.posts.length) {
      this.props.getNews();
    }
  }

  renderContent = content => {
    const filter1 = content.replace('<div class="post_excerpt animate">', '');
    const filter2 = filter1.replace('</div>', '');
    return filter2;
  };

  renderPost() {
    if (this.props.posts.length) {
      let index = findIndex(this.props.posts, post => {
        return post.id === parseInt(this.props.match.params.id, 10);
      });
      let post = this.props.posts[index];

      return (
        <>
          <StyledContainer>
            <Header>
              <h2>News</h2>
              <h1
                dangerouslySetInnerHTML={{
                  __html: post.title.rendered,
                }}
              />
              <span>{dayjs(post.date).format('MMM D, YYYY')}</span>
            </Header>
          </StyledContainer>
          <ContentWrapper>
            <Container>
              {post.content.rendered && (
                <Content
                  dangerouslySetInnerHTML={{
                    __html: this.renderContent(post.content.rendered),
                  }}
                >
                  {/* {post.content.rendered} */}
                </Content>
              )}
              {post._embedded['wp:featuredmedia'] && (
                <ImageBlock
                  image={post._embedded['wp:featuredmedia'][0].source_url}
                  bgPositionBottom={post.title.rendered==='Property Sold'}
                />
              )}
              <Bottom>
                <Link to="/news">Back to News</Link>
              </Bottom>
            </Container>
          </ContentWrapper>
        </>
      );
    }
  }

  render() {
    return (
      <Root>
        <Page />
        <LastSection>{this.renderPost()}</LastSection>
      </Root>
    );
  }
}

const mapStateToProps = state => ({
  posts: state.news.posts,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getNews,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewsSingle));

const Root = styled.div``;

const StyledContainer = styled(Container)`
  margin-top: ${vw(249)};
  padding-bottom: ${vw(80)};
  z-index: 1;
  position: relative;
  .post_excerpt {
    height: auto;
  }
  @media (max-width: ${media.sm}) {
    margin-top: ${vwTablet(120)};
    padding-bottom: ${vwTablet(67)};
  }
  @media (max-width: ${media.xs}) {
    margin-top: ${vwMobile(71)};
    padding-bottom: ${vwMobile(40)};
  }
`;

const Header = styled.header`
  color: #fff;
  h1 {
    font-size: ${vw(50)};
    font-family: ${({ theme }) => theme.fontHeader};
    max-width: 60%;
    margin-bottom: ${vw(20)};
    letter-spacing: ${vw(1)};
    line-height: ${vw(60)};
    @media (max-width: ${media.sm}) {
      max-width: 100%;
      font-size: ${vwTablet(42)};
      margin-bottom: ${vwTablet(20)};
      letter-spacing: ${vwTablet(0.84)};
      line-height: ${vwTablet(50)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(30)};
      margin-bottom: ${vwMobile(20)};
      letter-spacing: ${vwMobile(0.5)};
      line-height: ${vwMobile(36)};
    }
  }
  h2 {
    font-size: ${vw(20)};
    margin-bottom: ${vw(30)};
    letter-spacing: ${vw(1.5)};
    line-height: ${vw(32)};
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(18)};
      margin-bottom: ${vwTablet(30)};
      letter-spacing: ${vwTablet(1.35)};
      line-height: ${vwTablet(32)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(16)};
      margin-bottom: ${vwMobile(20)};
      letter-spacing: ${vwMobile(0.5)};
      line-height: ${vwMobile(24)};
    }
  }
  span {
    font-size: ${vw(13)};
    line-height: ${vw(13)};
    letter-spacing: ${vw(1.5)};
    @media (max-width: ${media.sm}) {
      opacity: 0.8;
      font-size: ${vwTablet(13)};
      line-height: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1.5)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(12)};
      line-height: ${vwMobile(12)};
      letter-spacing: ${vwMobile(1)};
    }
  }
`;

const ContentWrapper = styled.div`
  background: #fff;
  padding-top: ${vw(80)};
  padding-bottom: ${vw(40)};
`;

const Content = styled.div`
  margin-bottom: ${vw(20)};
  color: #9b9b9b;
  font-size: ${vw(16)};
  /* line-height: ${vw(1.625)}; */
  line-height:${vw(16)};
  /* set content styles here */
  @media (max-width: ${media.sm}) {
    margin-bottom: ${vwTablet(20)};
    font-size: ${vwTablet(16)};
    line-height: ${vwTablet(26)};
    letter-spacing: 0;
  }
  @media (max-width: ${media.xs}) {
    margin-bottom: ${vwMobile(20)};
    font-size: ${vwMobile(14)};
    line-height: ${vwMobile(18)};
    letter-spacing: 0;
  }
  h1 {
  }
  h2 {
  }
  h3 {
  }
  h4 {
  }
  h5 {
  }
  h6 {
  }
  p {
    font-size: ${vw(16)};
    margin-bottom: ${vw(20)};
    @media (max-width: ${media.sm}) {
      margin-bottom: ${vwTablet(20)};
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(26)};
      letter-spacing: 0;
    }
    @media (max-width: ${media.xs}) {
      margin-bottom: ${vwMobile(20)};
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(18)};
      letter-spacing: 0;
    }
  }
  a {
  }
  ul {
  }
  li {
  }
  img {
    max-width: 100%;
  }
`;

const ImageBlock = styled.div`
  width: 100%;
  height: ${vw(400)};
  background: url(${props => props.image});
  background-size: cover;
  background-position: ${props => props.bgPositionBottom ? '0% 90%' : 'center center'};
  margin-bottom: ${vw(20)};
  @media (max-width: ${media.sm}) {
    width: 100%;
    height: ${vwTablet(300)};
    margin-bottom: ${vwTablet(20)};
  }
  @media (max-width: ${media.xs}) {
    width: 100%;
    height: ${vwMobile(150)};
    margin-bottom: ${vwMobile(20)};
  }
`;

const Bottom = styled.div`
  margin-top: ${vw(90)};
  @media (max-width: ${media.sm}) {
    margin-top: ${vwTablet(69)};
  }
  @media (max-width: ${media.xs}) {
    margin-top: ${vwMobile(49)};
  }

  a {
    color: ${({ theme }) => theme.colorDarkBlue};
    font-weight: bold;
    font-size: ${vw(16)};
    letter-spacing: ${vw(0.5)};
    text-transform: uppercase;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(13)};
      line-height: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1.5)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(16)};
      letter-spacing: ${vwMobile(0.44)};
    }
  }
`;
