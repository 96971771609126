import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from 'src/styles/media';

import Logo from 'components/Logo';
import Header from 'components/Header/Header';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const Page = ({ children, smallLogo, customPadding }) => {
  return (
    <Root>
      <Header />
      <StickyWrapper smallLogo={smallLogo} customPadding={customPadding}>
        <LogoImageWrapper smallLogo={smallLogo}>
          <Logo />
        </LogoImageWrapper>
      </StickyWrapper>
      <Children>{children}</Children>
    </Root>
  );
};

Page.propTypes = {
  smallLogo: PropTypes.bool,
};

Page.defaultProps = {
  smallLogo: true,
};

const Root = styled.div``;

const StickyWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  padding-top: ${vw(40)};
  margin-bottom: ${props =>
    props.customPadding ? `${vw(props.customPadding)}` : `${vw(-188)}`};
  pointer-events: none;
  z-index: 10;
  transition: all 1.1s ease-out;
  @media (max-width: ${media.sm}) {
    padding-top: ${vwTablet(80)};
    /* padding-top: 0; */
  }
  /* @media (max-width: ${media.xs}) {
    padding-top: ${vwMobile(40)};
    margin-bottom: ${props =>
      props.customPadding
        ? `${vwMobile(props.customPadding)}`
        : `${vwMobile(-188)}`};
  } */
`;

const LogoImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  img {
    position: absolute;
    top: ${props => (props.smallLogo ? '0' : '50%')};
    left: 0;
    transform: ${props =>
      props.smallLogo
        ? 'translate3d(50%, 50%, 0)'
        : 'translate3d(-50%, -50%, 0)'};
    width: ${props => (props.smallLogo ? '4.67%' : '30%')};
    height: auto;
    pointer-events: auto;
    transition: all 1s ease-out;
    @media (max-width: ${media.sm}) {
      width: ${props => (props.smallLogo ? '7%' : '50%')};
      top: ${props => (props.smallLogo ? '0' : '25%')};
      transform: ${props =>
        props.smallLogo
          ? 'translate3d(50%, 50%, 0)'
          : 'translate3d(-50%, -50%, 0) scale(0.6)'};
    }
    @media (max-width: ${media.xs}) {
      width: ${props => (props.smallLogo ? '9.34%' : '50%')};
      top: ${props => (props.smallLogo ? '0' : '37%')};
      transform: ${props =>
        props.smallLogo
          ? 'translate3d(50%, 50%, 0)'
          : 'translate3d(-50%, -50%, 0) scale(0.8)'};
    }
  }
`;

const Children = styled.div`
  padding-top: ${vw(148)};
  @media (max-width: ${media.sm}) {
    padding-top: ${vwTablet(20)};
  }
  @media (max-width: ${media.xs}) {
    padding-top: ${vwMobile(10)};
  }
`;

export default Page;
