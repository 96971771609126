import React, { PureComponent } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getNews } from 'src/redux/news';

import Page from 'components/Page';
import LastSection from 'components/LastSection';
import Container from 'components/Container';
import Card from './elements/Card';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

class News extends PureComponent {
  // state = {
  //   news: [],
  // };

  componentDidMount() {
    if (!this.props.posts.length) {
      this.props.getNews();
    }
  }

  // initialRenderNews() {
  //   return (
  //     <Fragment>
  //       {this.state.news.map(news => (
  //         <Card
  //           postTime={news.title.rendered}
  //           label={news.title.rendered}
  //           image={news._embedded['wp:featuredmedia'][0].source_url}
  //         />
  //       ))}
  //     </Fragment>
  //   );
  // }

  render() {
    return (
      <Root>
        <Page />
        <LastSection>
          <BGShapeTop />
          <BGShapeBottom />
          <StyledContainer>
            <h1>News</h1>
            {this.props.posts &&
              this.props.posts.map((post, index) => (
                <Card post={post} key={index} />
              ))}
            {/* {this.initialRenderNews} */}
          </StyledContainer>
        </LastSection>
      </Root>
    );
  }
}

const Root = styled.div`
  position: relative;
  background: #ffffff;
  h1 {
    font-family: ${({ theme }) => theme.fontHeader};
    font-size: ${vw(50)};
    font-weight: bold;
    line-height: ${vw(50)};
    letter-spacing: ${vw(1)};
    color: white;
    margin-bottom: ${vw(43)};
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(42)};
      line-height: ${vwTablet(50)};
      letter-spacing: ${vwTablet(0.84)};
      margin-bottom: ${vwTablet(30)};
    }
    @media (max-width: ${media.xs}) {
      margin-top: ${vwMobile(71)};
      padding-bottom: ${vwMobile(35)};
      font-size: ${vwMobile(30)};
      line-height: ${vwMobile(36)};
      letter-spacing: ${vwMobile(0.5)};
    }
  }
`;

const StyledContainer = styled(Container)`
  margin-top: ${vw(205)};
  padding-bottom: ${vw(147)};
  z-index: 1;
  position: relative;
  @media (max-width: ${media.sm}) {
    margin-top: ${vwTablet(120)};
    padding-bottom: ${vwTablet(67)};
  }
  @media (max-width: ${media.xs}) {
    margin-top: ${vwMobile(71)};
    padding-bottom: ${vwMobile(40)};
  }
`;

const BGShapeTop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;
  background: url(${require('src/assets/images/bg-shapes/news/shape_1.png')});
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
  pointer-events: none;
  @media (max-width: ${media.sm}) {
    /* display: none; */
  }
`;

const BGShapeBottom = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url(${require('src/assets/images/bg-shapes/news/shape_2.png')});
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
  pointer-events: none;
  @media (max-width: ${media.sm}) {
    /* display: none; */
  }
`;

const mapStateToProps = state => ({
  posts: state.news.posts,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getNews,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(News);
