import React, { Component } from 'react';
import styled from 'styled-components';

import Input from './elements/Input';
import Button from 'components/Button';

class Form extends Component {
  render() {
    return (
      <Root
        method="post"
        action="http://bam.bamcommunications.ca/enera_mailer.php"
      >
        <input
          type="hidden"
          name="retURL"
          value={window.location.origin + '/contact-us/thank-you'}
        />
        <Input label="Name" name="name" required />
        <Input
          label="Email"
          name="email"
          required
          pattern="^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
        />
        <Input label="Phone Number" name="tel" required />
        <Input label="Message" name="message" required />
        <Button label="Submit" background="#00205C" />
      </Root>
    );
  }
}

export default Form;

const Root = styled.form`
  width: 100%;
`;
