import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Parallax, withController } from 'react-scroll-parallax';

import Page from 'components/Page';
import LastSection from 'components/LastSection';
import Container from 'components/Container';
import Description from 'components/Description';
import Project from './elements/Project';

const text = {
  title: 'Development',
  body:
    'As experienced real estate developers, our communities focus on creating unique design concepts that incorporate architectural innovation. This philosophy is based on creating a distinctive competitive advantage in the marketplace and building a reputation as a community builder with products that are inherently more engaging and desirable.',
  tagline: 'Architectural Innovation',
};

class Communities extends PureComponent {
  handleLoad = () => {
    // updates cached values after image dimensions have loaded
    this.props.parallaxController.update();
  };

  render() {
    return (
      <Root onLoad={this.handleLoad}>
        <Page>
          <BGShapeTop>
            <Parallax y={[-20, 5]}>
              <img
                src={require('src/assets/images/communities/bg-shapes/dev_shape_1.png')}
                alt="bg-shape"
                onLoad={this.handleLoad}
              />
            </Parallax>
          </BGShapeTop>
          <BGShapeBottom>
            <Parallax y={[-40, 15]}>
              <img
                src={require('src/assets/images/communities/bg-shapes/shape_2.png')}
                alt="bg-shape"
                onLoad={this.handleLoad}
              />
            </Parallax>
          </BGShapeBottom>
        </Page>

        <LastSection>
          <Container>
            <Description text={text} />
          </Container>
          <Project
            name="Cambie Village"
            detail="Located in the upcoming Cambie Village district, this development project will be part of the first stage of a new townhome community approved through the Cambie Corridor Phase 3 initiative. Our vision for this community is to introduce 36,000 square feet of contemporary living townhomes with unit size mixes that appeal to wide consumer demographic."
            location="Vancouver, BC"
            size="36,000 sq. ft."
            type="Townhomes"
            completion="Summer 2025"
            websiteURL=""
            sliders={[
              require('src/assets/images/communities/project-cambie/6.jpg'),
              // require('src/assets/images/communities/project-cambie/2.jpg'),
              // require('src/assets/images/communities/project-cambie/3.jpg'),
              // require('src/assets/images/communities/project-cambie/4.jpg'),
              // require('src/assets/images/communities/project-cambie/5.jpg'),
            ]}
          />
        </LastSection>
        {/* <LayoutWithProjects
          text={text}
          projects={projects}
          type={'communities'}
        /> */}
      </Root>
    );
  }
}

const Root = styled.div`
  background-color: #648dc6;
`;

const BGShapeTop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  & img {
    width: 100%;
    height: auto;
  }
`;

const BGShapeBottom = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  & img {
    width: 100%;
    height: auto;
  }
`;

export default withController(Communities);
