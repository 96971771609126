import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'src/styles/media';

const Spacing = props => {
  return <Wrapper {...props} />;
};

Spacing.propTypes = {
  value: PropTypes.string,
};

Spacing.defaultProps = {
  value: '0',
};

const Wrapper = styled.div`
  margin-bottom: ${props => props.value};

  ${props =>
    props.xs &&
    `@media (max-width: ${media.xs}) {
      margin-bottom: ${props.xs};
    }`};
  
  ${props =>
    props.sm &&
    `@media (max-width: ${media.sm}) {
      margin-bottom: ${props.sm};
    }`}

  ${props =>
    props.md &&
    `@media (max-width: ${media.sm}) {
      margin-bottom: ${props.md};
    }`}

  ${props =>
    props.lg &&
    `@media (max-width: ${media.lg}) {
      margin-bottom: ${props.lg};
    }`}

  ${props =>
    props.xl &&
    `@media (max-width: ${media.xl}) {
      margin-bottom: ${props.xl};
    }`}

  ${props =>
    props.xsup &&
    `@media (min-width: ${media.xsup}) {
      margin-bottom: ${props.xsup};
    }`};
  
  ${props =>
    props.smup &&
    `@media (min-width: ${media.mdup}) {
      margin-bottom: ${props.smup};
    }`}

  ${props =>
    props.mdup &&
    `@media (min-width: ${media.mdup}) {
      margin-bottom: ${props.mdup};
    }`}

  ${props =>
    props.lgup &&
    `@media (min-width: ${media.lgup}) {
      margin-bottom: ${props.lgup};
    }`}

  ${props =>
    props.xlup &&
    `@media (min-width: ${media.xlup}) {
      margin-bottom: ${props.xlup};
    }`}
`;

export default Spacing;
