import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Parallax, withController } from 'react-scroll-parallax';

import Button from 'components/Button';
import Page from 'components/Page';
import Container from 'components/Container';
import LastSection from 'components/LastSection';

import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

class ThankYou extends PureComponent {
  handleLoad = () => {
    // updates cached values after image dimensions have loaded
    this.props.parallaxController.update();
  };

  render() {
    return (
      <Root>
        <Page>
          <BGShapeTop>
            <Parallax y={[-20, 1]}>
              <img
                src={require('src/assets/images/bg-shapes/thank-you/shape_1.png')}
                alt="bg-shape"
                onLoad={this.handleLoad}
              />
            </Parallax>
          </BGShapeTop>
          <BGShapeBottom>
            <Parallax y={[-40, 20]}>
              <img
                src={require('src/assets/images/bg-shapes/thank-you/shape_2.png')}
                alt="bg-shape"
                onLoad={this.handleLoad}
              />
            </Parallax>
          </BGShapeBottom>
        </Page>

        <LastSection>
          <StyledContainer>
            <h1>Thank you</h1>{' '}
            <Body>
              Thank you for contacting us, we’ll get back to you as soon as
              possible.{' '}
            </Body>{' '}
            <ButtonWrapper>
              <Button label="Homepage" link="/" />{' '}
            </ButtonWrapper>
          </StyledContainer>
        </LastSection>
      </Root>
    );
  }
}

export default withController(ThankYou);

const ButtonWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 257px;
`;

const Root = styled.div`
  background-color: #648dc6;
  /* position: relative; */
`;

const StyledContainer = styled(Container)`
  margin-top: ${vw(205)};
  padding-bottom: ${vw(156)};
  z-index: 2;
  position: relative;
  @media (max-width: ${media.sm}) {
    margin-top: ${vwTablet(120)};
    padding-bottom: ${vwTablet(60)};
  }
  @media (max-width: ${media.xs}) {
    margin-top: ${vwMobile(71)};
    padding-bottom: ${vwMobile(20)};
  }
  h1 {
    font-family: ${({ theme }) => theme.fontHeader};
    font-size: ${vw(50)};
    font-weight: bold;
    line-height: ${vw(50)};
    letter-spacing: ${vw(1)};
    margin-bottom: ${vw(58)};
    color: white;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(42)};
      line-height: ${vwTablet(50)};
      letter-spacing: ${vwTablet(0.84)};
      margin-bottom: ${vwTablet(30)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(30)};
      line-height: ${vwMobile(36)};
      letter-spacing: ${vwMobile(0.5)};
      margin-bottom: ${vwMobile(20)};
    }
  }
`;

const BGShapeTop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  & img {
    width: 100%;
    height: auto;
    opacity: 0.8;
  }
`;

const BGShapeBottom = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  & img {
    width: 100%;
    height: auto;
  }
`;

const Body = styled.p`
  font-family: ${({ theme }) => theme.fontBody};
  font-size: ${vw(20)};
  line-height: ${vw(30)};
  letter-spacing: ${vw(0.5)};
  color: white;
  @media (max-width: ${media.sm}) {
    font-size: ${vwTablet(18)};
    line-height: ${vwTablet(28)};
    letter-spacing: ${vwTablet(0.5)};
    color: white;
  }
  @media (max-width: ${media.xs}) {
    font-size: ${vwMobile(14)};
    line-height: ${vwMobile(20)};
    letter-spacing: 0;
  }
`;
