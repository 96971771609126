import React from 'react';
import styled, { css } from 'styled-components';

const TextBlock = ({ type, text }) => {
  return type === 'project' ? (
    <Root>
      <h1>{text.title}</h1>
      <p>{text.body}</p>

      <h2>Location</h2>
      <p>{text.location}</p>

      <h2>Size</h2>
      <p>{text.size}</p>

      <h2>Type</h2>
      <p>{text.type}</p>

      <h2>Completion</h2>
      <p>{text.completion}</p>

      <a href={text.link} target="_blank" rel="noopener noreferrer">
        Visit Website
      </a>
    </Root>
  ) : (
    <RootInvestment>
      <h1>{text.title}</h1>
      <Stats>
        <div>
          <h2>Location</h2>
          <p>{text.location}</p>
        </div>

        <div>
          <h2>Type</h2>
          <p>{text.type}</p>
        </div>

        <div>
          <h2>Size</h2>
          <p>{text.size}</p>
        </div>

        <div>
          <h2>Lease</h2>
          <p>{text.lease}</p>
        </div>
      </Stats>
    </RootInvestment>
  );
};

export default TextBlock;

const textStyles = css`
  h1 {
    font-family: ${({ theme }) => theme.fontBody};
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1.5px;
    color: white;
    margin-bottom: 21px;
  }
  p {
    opacity: 0.8;
    font-family: ${({ theme }) => theme.fontBody};
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 26px;
  }
  h2 {
    font-family: ${({ theme }) => theme.fontBody};
    font-size: 13px;
    color: #808285;
    letter-spacing: 1.5px;
    line-height: 13px;
    text-transform: uppercase;
    margin-top: 40px;
  }
  a {
    font-size: 14px;
    color: #ff8300;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 40px;
    display: block;
  }
`;
const Root = styled.div`
  ${textStyles};
`;

const Stats = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > div {
    width: 50%;
  }
`;

const RootInvestment = styled.div`
  ${textStyles};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
