import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import media from 'src/styles/media';

import Intl from 'components/Intl';
import Logo from 'components/Logo';

import { vwTablet, vwMobile } from 'src/styles/utils';

const NavigationMobile = ({ links, isOpen }) => {
  return (
    <Intl>
      {(t, { locale, getLocaleURL }) => (
        <Root open={isOpen}>
          <Nav className={isOpen ? 'active' : ''}>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <NavLink
              to={`${getLocaleURL()}/`}
              style={{ animationDelay: '0.15s' }}
            >
              home
            </NavLink>
            {links.map((link, index) => (
              <NavLink
                to={`${getLocaleURL()}${link.url}`}
                key={link.url}
                activeClassName="activeLink"
                style={{
                  animationDelay: `${0.4 + 0.15 * index}s`,
                }}
              >
                {link.id}
              </NavLink>
            ))}
            {/* <ButtonContainer
              style={{ animationDelay: `${0.5 + 0.2 * links.length}s` }}
            >
              <Button
                id="mobilecontact"
                to={`${getLocaleURL()}/contact`}
                width={125}
                height={35}
              >
                {t.menu.contact}
              </Button>
            </ButtonContainer> */}
            {/* <Locales style={{ animationDelay: `${0.7 + 0.2 * links.length}s` }}>
              <Link to="/" className={locale === 'en' ? 'active' : ''}>
                EN
              </Link>
              |
              <Link to="/zh" className={locale === 'zh' ? 'active' : ''}>
                中文
              </Link>
            </Locales> */}
          </Nav>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  width: 100%;
  height: 100vh;
  max-height: ${props => (props.open ? '100vh' : '0')};
  background: ${({ theme }) => theme.colorDarkBlue};
  transition: all 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
  display: none;
  @media (max-width: ${media.sm}) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
  }
`;

const animateLink = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const Nav = styled.nav`
  position: absolute;
  top: 42.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.25s ease;
  @media (max-width: ${media.sm}) {
    top: 50%;
  }

  a {
    display: block;
    padding: ${vwTablet(30)} 0;
    text-transform: uppercase;
    transition: all 0.5s;
    font-family: ${({ theme }) => theme.fontBody};
    font-size: ${vwTablet(20)};
    font-weight: 600;
    line-height: ${vwTablet(16)};
    letter-spacing: ${vwTablet(1.3)};
    text-align: center;
    color: white;
    transition: all 0.25s ease;
    &:hover {
      color: ${({ theme }) => theme.colorOrange};
    }
    &.activeLink {
      color: ${({ theme }) => theme.colorOrange};
    }
    @media (max-width: ${media.xs}) {
      padding: ${vwMobile(15)} 0;
      font-size: ${vwMobile(20)};
      line-height: ${vwMobile(16)};
      letter-spacing: ${vwMobile(1.26)};
    }
  }
  &.active {
    opacity: 1;
    & > * {
      opacity: 0;
      transition: none;
      animation: ${animateLink} 1s forwards;
    }
  }
`;

const LogoWrapper = styled.div`
  margin-bottom: ${vwTablet(30)};
  @media (max-width: ${media.xs}) {
    margin-bottom: ${vwMobile(50)};
  }
  img {
    @media (max-width: ${media.xs}) {
      width: ${vwMobile(50)};
      height: ${vwMobile(58)};
    }
  }
`;

// const ButtonContainer = styled.div`
//   padding: ${vwTablet(15)} 0;
//   @media (max-width: ${media.xs}) {
//     margin-bottom: ${vwMobile(15)};
//   }
//   a {
//     padding: 0;
//   }
// `;

// const Locales = styled.div`
//   width: 100%;
//   a {
//     display: inline-block;
//     padding: ${vwTablet(15)};
//     @media (max-width: ${media.xs}) {
//       margin-bottom: ${vwMobile(15)};
//     }
//     &.active {
//       color: ${({ theme }) => theme.colorPrimary};
//     }
//   }
// `;

export default NavigationMobile;
