import React from 'react';
import styled from 'styled-components';

const Wechat = () => {
  return (
    <Root
      width="29px"
      height="25px"
      viewBox="0 0 29 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>wechat</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Desktop"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Contact"
          transform="translate(-749.000000, -600.000000)"
          fill="#FFFFFF"
          fill-rule="nonzero"
        >
          <g id="wechat" transform="translate(749.000000, 600.000000)">
            <path
              d="M10.6852934,0 C4.78344967,0 0,4.01389356 0,8.96358543 C0,11.817591 1.59923224,14.3489076 4.08000119,15.987451 L2.67132334,18.8235294 L6.50912455,17.1688515 C7.33189214,17.4628571 8.18671561,17.7048739 9.10030819,17.8178151 C8.97564644,17.2710364 8.90441115,16.709916 8.90441115,16.1344538 C8.90441115,11.1919328 13.6967652,7.17086835 19.5897045,7.17086835 C20.1293118,7.17086835 20.656453,7.21568627 21.1764706,7.28022409 C20.2361648,3.13546218 15.8997165,0 10.6852934,0 Z M7.12352892,6.72268908 C6.38624367,6.72268908 5.78786725,6.12033613 5.78786725,5.37815126 C5.78786725,4.63596639 6.38624367,4.03361345 7.12352892,4.03361345 C7.86081416,4.03361345 8.45919059,4.63596639 8.45919059,5.37815126 C8.45919059,6.12033613 7.86081416,6.72268908 7.12352892,6.72268908 Z M14.2470578,6.72268908 C13.5097726,6.72268908 12.9113962,6.12033613 12.9113962,5.37815126 C12.9113962,4.63596639 13.5097726,4.03361345 14.2470578,4.03361345 C14.9843431,4.03361345 15.5827195,4.63596639 15.5827195,5.37815126 C15.5827195,6.12033613 14.9843431,6.72268908 14.2470578,6.72268908 Z"
              id="Shape"
            />
            <path
              d="M28.5294118,16.0947712 C28.5294118,12.1604412 24.5453088,8.97058824 19.6323529,8.97058824 C14.7193971,8.97058824 10.7352941,12.1604412 10.7352941,16.0947712 C10.7352941,20.0291013 14.7193971,23.2189542 19.6323529,23.2189542 C20.4402059,23.2189542 21.2089118,23.1049673 21.9544853,22.9428922 L26.75,25 L25.0915882,21.6819118 C27.1699412,20.3781863 28.5294118,18.3727288 28.5294118,16.0947712 Z M16.9632353,15.6495098 C16.2265588,15.6495098 15.6286765,15.0510784 15.6286765,14.3137255 C15.6286765,13.5763725 16.2265588,12.9779412 16.9632353,12.9779412 C17.6999118,12.9779412 18.2977941,13.5763725 18.2977941,14.3137255 C18.2977941,15.0510784 17.6999118,15.6495098 16.9632353,15.6495098 Z M22.3014706,15.6495098 C21.5647941,15.6495098 20.9669118,15.0510784 20.9669118,14.3137255 C20.9669118,13.5763725 21.5647941,12.9779412 22.3014706,12.9779412 C23.0381471,12.9779412 23.6360294,13.5763725 23.6360294,14.3137255 C23.6360294,15.0510784 23.0381471,15.6495098 22.3014706,15.6495098 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </Root>
  );
};

const Root = styled.svg`
  width: 100%;
  height: auto;
  &:hover {
    g {
      fill: #ff8300;
      transition: fill 0.4s ease;
    }
  }
`;

export default Wechat;
