import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import Button from 'components/Button';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const Terms = () => {
  return (
    <Root>
      <TermsOfUse>
        <h1>Terms of Use</h1>
        <p>
          The material, images, renderings and information on this website is
          provided on an ‘as is’ basis solely for the reader’s general
          knowledge. The information is not intended to be a comprehensive
          review of all matters and developments concerning Enera Enterprises
          Ltd., and therefore assumes no responsibility and provides no
          warranty, either express or implied, as to its accuracy, completeness
          or currency. In no event will Enera Enterprises Ltd. be liable for any
          special, incidental, indirect or consequential damages of any kind, or
          any damages whatsoever arising out of or in connection with the use or
          performance of this information, including without limitation, those
          resulting from loss of use, data or profits, whether or not advised of
          the possibility of damage. This site may contain links to external
          organizations. We do not control the relevance, timeliness, or
          accuracy of materials produced by other organizations, nor do we
          endorse their views, products or services. Your access and use of this
          website constitutes your agreement to be governed by the contents of
          the disclaimer set forth above.
        </p>
      </TermsOfUse>

      <PrivacyPolicy>
        <h1>Privacy Policy</h1>
        <p>
          Enera Enterprises Ltd. and each of our affiliates and partnerships is
          committed to maintaining the accuracy, confidentiality and security of
          your personal information. This Privacy Policy describes the personal
          information that Enera Enterprises Ltd. collects from or about you,
          how we use that information, and to whom we disclose that information.
        </p>
        <h2>Collecting Personal Information</h2>
        <p>
          Enera Enterprises Ltd. collects personal information to better serve
          your needs, whether purchasing products and services from us or
          obtaining service or warranty work. In order to provide you with our
          wide variety of services, products and promotions, we may collect the
          following information:
        </p>
        <ul>
          <li>
            {' '}
            Contact and mailing information such as name, address, telephone
            numbers, fax numbers and email addresses.
          </li>

          <li>
            The plans, specifications and other particulars about the home that
            is being built for you.
          </li>
          <li>Municipal and legal descriptions for you home.</li>

          <li>
            Any other relevant information necessary to provide you with our
            products and services.
          </li>
          <li>
            We may need to collect other personal information depending on the
            circumstance, but we will ask you directly and ensure that you
            agree.
          </li>
        </ul>

        <h2>Personal Information Security</h2>
        <p>
          Like you, Enera Enterprises Ltd. values our personal information. Part
          of valuing your personal information is making sure that it is
          protected and kept confidential. We achieve this by:
        </p>
        <ul>
          <li>Having our employees sign a confidentiality agreement.</li>

          <li>
            Having up to date computer security such as passwords, encryption
            and firewalls.
          </li>

          <li>
            Where third parties are hired to assist us with providing products
            and services to you for your home, we have them sign a privacy and
            confidentiality agreement to ensure that your personal information
            is protected by them.
          </li>
        </ul>
        <h2>Personal Information Updating</h2>
        <p>
          Enera Enterprises Ltd. wants to ensure that your personal information
          is always correct up to date. If your personal information changes or
          is incorrect, updates can be made by contacting your representative
          with the change and any other information you feel is necessary.
          <br />
          We will keep your information as long as is necessary to continue to
          meet your customer needs and any legal requirements we may have.
          However, once your personal information no longer needed, we will
          destroy it.
        </p>

        <h2>Personal Data</h2>
        <p>
          We only collect Personal Data from you, such as first and last name,
          phone number, e-mail, when you register to receive information. We
          collect data about your activities that does not personally or
          directly identify you when you visit our website, or the websites and
          online services where we display advertisements. This information may
          include the content you view, the date and time that you view this
          content, the products you purchase, or your location information
          associated with your IP address. We use the information we collect to
          serve you more relevant advertisements (referred to as “Retargeting”).
          We collect information about where you saw the ads we serve you and
          what ads you clicked on.
          <br />
          As you browse www.enera.ca, online ad networks we work with may place
          anonymous cookies on your computer, and use similar technologies, in
          order to understand your interests based on your (anonymous) online
          activities, and thus to tailor more relevant ads to you. If you do not
          wish to receive such tailored advertising, you can visit this page to
          opt out of most companies that engage in such advertising. (This will
          not prevent you from seeing ads; the ads simply will not be delivered
          through these targeting methods.)
          <br />
          We do not target ads to you based on sensitive personal data, such as
          information related to race or ethnic origins, political opinions,
          religious beliefs or other beliefs of a similar nature, trade union
          membership, physical or mental health or condition or sexual life. We
          may target ads based on data provided by Advertisers or partners alone
          or in combination with the data we collect ourselves. Any data used to
          serve targeted advertisements is de-identified and is not used to
          personally or directly identify you. In order to allow us to reach the
          best inventory online, we work with third party advertising companies
          (our “Advertising Partners”) to help us recognize you and serve
          relevant advertisements to you when you visit a website or online
          service in their network. We also work with Advertising Partners who
          help us recognize you across different devices in order to show you
          relevant advertisements. Our Advertising Partners may collect
          information about your activities on our website, and other websites
          or online services in their networks. We also work with third party
          companies to assist us with website analytics such as evaluating the
          use and operation of our website so that we can continue to enhance
          the website and our services.
          <br />
          We and our third-party partners use session cookies and persistent
          cookies to make it easier for you to navigate and enhance the
          experience of our site, and to monitor and evaluate our website’s
          operation and use.
          <br />
          To modify or delete your personal information, or to opt out of future
          communications, please contact us at:
        </p>
      </PrivacyPolicy>

      <Contact>
        <h2>Enera Enterprises LTD.</h2>

        <p>
          <a
            href="https://www.google.com/maps/place/550+W+Broadway,+Vancouver,+BC+V5Z+0A9/data=!4m2!3m1!1s0x548673dd6afa279b:0x8e72f1ab8357a0de?ved=2ahUKEwiY19Kt4e7gAhVK7J4KHVVUBpcQ8gEwAHoECAAQAQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            Unit 610 – 550 West Broadway Vancouver, BC V5Z 0E9
          </a>
          <br />
          <a href="tel:604-559-8383">Tel: 604-559-8383</a>
          <br />
          <a href="fax:604-559-8322">Fax: 604-559-8322</a>
          <br />
          <a
            href="mailto:privacy@enera.ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            Email: privacy@enera.ca
          </a>
        </p>
      </Contact>

      <ButtonWrapper>
        <Button label="Homepage" link="/" />{' '}
      </ButtonWrapper>
    </Root>
  );
};

export default Terms;

const Root = styled.div`
  color: ${({ theme }) => theme.white};
  padding: ${vw(80)};
  h1 {
    font-family: ${({ theme }) => theme.fontHeader};
    font-size: ${vw(50)};
    line-height: ${vw(50)};
    letter-spacing: ${vw(1)};
    margin-bottom: ${vw(40)};
    color: white;

    @media (max-width: ${media.sm}) {
      margin-bottom: ${vwTablet(30)};
      font-size: ${vwTablet(41)};
      font-weight: bold;
      line-height: ${vwTablet(50)};
      letter-spacing: ${vwTablet(0.8)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(30)};
      font-weight: bold;
      line-height: ${vwMobile(36)};
      letter-spacing: ${vwMobile(0.5)};
    }
  }
  h2 {
    font-family: ${({ theme }) => theme.fontHeader};
    font-weight: normal;
    font-size: ${vw(24)};
    line-height: ${vw(32)};
    color: white;
    text-transform: uppercase;
    margin-top: ${vw(80)};
    @media (max-width: ${media.sm}) {
      margin-bottom: ${vwTablet(30)};
      font-size: ${vwTablet(36)};
      line-height: ${vwTablet(36)};
    }
    @media (max-width: ${media.xs}) {
      margin-bottom: ${vwMobile(20)};
      font-size: ${vwMobile(24)};
      line-height: ${vwMobile(32)};
    }
  }
  p {
    font-family: ${({ theme }) => theme.fontBody};
    font-size: ${vw(16)};
    line-height: ${vw(26)};
    color: white;
    &:last-of-type {
      margin-bottom: ${vw(50)};
    }
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(26)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(18)};
    }
  }
  a {
    color: white;
    position: relative;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(14)};
      line-height: ${vwTablet(21)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(21)};
    }
  }
  li {
    color: ${({ theme }) => theme.white};
  }
`;

const TermsOfUse = styled.div``;

const PrivacyPolicy = styled.div`
  ul {
    color: ${({ theme }) => theme.white};
    list-style-type: disc;
    margin-left: ${vw(20)};
    li {
      color: white;
    }
  }
`;

const Contact = styled.div``;

const ButtonWrapper = styled.div``;
