import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const Button = ({ label, link, background }) => {
  return link ? (
    <RootLink to={link}>
      <span>{label}</span>
      <OpacityLayer />
    </RootLink>
  ) : (
    <Root background={background}>
      {/* <span>{label}</span> */}
      <input type="submit" label={label} />
    </Root>
  );
};

export default Button;

const OpacityLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  background: white;
`;

const buttonStyles = css`
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: white;
  position: relative;
  overflow: hidden;
  /* background: ${({ theme }) => theme.colorDarkBlue}; */

  :after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 490%;
    width: 140%;
    background: ${({ theme }) => theme.colorOrange};
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translateX(-98%) translateY(-15%) rotate(45deg);
    transform: translateX(-98%) translateY(-15%) rotate(45deg);
  }
  :hover:after {
    -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
    transform: translateX(-9%) translateY(-25%) rotate(45deg);
  }
  span {
    z-index: 1;
    position: relative;
    font-family: ${({ theme }) => theme.fontBody};
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 16px;
    font-size: 16px;
    z-index: 99;
  }
`;

const RootLink = styled(Link)`
  ${buttonStyles}
`;

const Root = styled.div`
  ${buttonStyles}
  background: ${props => props.background && props.background};
  cursor: pointer;

  input{
    width: 100%;
    height: 100%;
    position: relative;
    font-family: ${({ theme }) => theme.fontBody};
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 16px;
    font-size: 16px;
    z-index: 99;
    text-transform: uppercase;
    color: white;
    background: none;
    border: none;
    cursor: pointer;

  }
`;
