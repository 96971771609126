import React from 'react';
import styled from 'styled-components';

const Facebook = () => {
  return (
    <Root
      width="12px"
      height="25px"
      viewBox="0 0 12 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>facebook</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Desktop"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Contact"
          transform="translate(-625.000000, -600.000000)"
          fill="#FFFFFF"
        >
          <g id="facebook" transform="translate(625.000000, 600.000000)">
            <path
              d="M7.83287362,25 L3.13440321,25 L3.13440321,12.5 L0,12.5 L0,8.19213867 L3.13440321,8.19072266 L3.1293098,5.65283203 C3.1293098,2.13837891 4.08515586,0 8.23740754,0 L11.6944584,0 L11.6944584,4.30869141 L9.53392404,4.30869141 C7.91696378,4.30869141 7.8391914,4.91064453 7.8391914,6.034375 L7.83277567,8.19072266 L11.71875,8.19072266 L11.2606374,12.498584 L7.83600802,12.5 L7.83287362,25 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </Root>
  );
};

const Root = styled.svg`
  width: 100%;
  height: auto;
  &:hover {
    g {
      fill: #ff8300;
      transition: fill 0.4s ease;
    }
  }
`;

export default Facebook;
