import React, { PureComponent } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { Parallax, withController } from 'react-scroll-parallax';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getNews } from 'src/redux/news';

import Page from 'components/Page';
import LastSection from 'components/LastSection';
import Container from 'components/Container';
// import ContentColumnTwo from 'components/ContentColumnTwo';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

class Careers extends PureComponent {
  componentDidMount() {
    if (!this.props.posts.length) {
      this.props.getNews();
    }
  }

  handleLoad = () => {
    // updates cached values after image dimensions have loaded
    this.props.parallaxController.update();
  };

  render() {
    return (
      <Root>
        <Page>
          <BGShapeTop>
            <Parallax
              y={[-20, 5]}
            >
              <img
                src={require('src/assets/images/bg-shapes/careers/shape_1.png')}
                alt="bg-shape"
                onLoad={this.handleLoad}
              />
            </Parallax>
          </BGShapeTop>
          <BGShapeMiddle>
            <Parallax
              y={[-30, 3]}
            >
              <img
                src={require('src/assets/images/bg-shapes/careers/shape_2.png')}
                alt="bg-shape"
                onLoad={this.handleLoad}
              />
            </Parallax>
          </BGShapeMiddle>
          <BGShapeBottom>
            <Parallax
              y={[-10, 10]}
            >
              <img
                src={require('src/assets/images/bg-shapes/careers/shape_3.png')}
                alt="bg-shape"
                onLoad={this.handleLoad}
              />
            </Parallax>
          </BGShapeBottom>
        </Page>
        <LastSection>
          <StyledContainer>
            <h1>Careers</h1>
            <ContentColumnTwo>
              <Left>
                <img
                  src={require('src/assets/images/careers/careers.jpg')}
                  alt="careers"
                />
              </Left>
              <Right>
                <Info>
                  <Intro>
                    We currently do not have any career opportunities available.
                    To have your information kept on file, please email your
                    resume to{' '}
                    <a
                      href="mailto:careers@enera.ca"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      careers@enera.ca
                    </a>{' '}
                    and we will contact you when an applicable position becomes
                    available.
                  </Intro>
                  {/* <Jobs>
                    {this.props.posts &&
                      this.props.posts.map(post => (
                        <Job>
                          <h3>{post.title.rendered}</h3>
                          <p>{post.title.rendered}</p>
                        </Job>
                      ))}
                  </Jobs> */}
                  {/* <Intro>
                    Donec facilisis tortor ut augue lacinia, at viverra est
                    semper. Sed sapien metus, scelerisque nec pharetra id,
                    tempor a tortor. Pellentesque non dignissim neque.
                  </Intro> */}
                  {/* <Email>
                    <a href="mailto:careers@enera.ca">
                      careers@enera.ca
                    </a>
                  </Email> */}
                </Info>
              </Right>
            </ContentColumnTwo>
            {/* <Tagline>aaa</Tagline> */}
          </StyledContainer>
        </LastSection>
      </Root>
    );
  }
}

const Root = styled.div`
  background: #547dbf;
  position: relative;
`;

const StyledContainer = styled(Container)`
  margin-top: ${vw(205)};
  padding-bottom: ${vw(156)};
  z-index: 2;
  position: relative;
  @media (max-width: ${media.sm}) {
    margin-top: ${vwTablet(120)};
    padding-bottom: ${vwTablet(60)};
  }
  @media (max-width: ${media.xs}) {
    margin-top: ${vwMobile(71)};
    padding-bottom: ${vwMobile(20)};
  }
  h1 {
    font-family: ${({ theme }) => theme.fontHeader};
    font-size: ${vw(50)};
    font-weight: bold;
    line-height: ${vw(50)};
    letter-spacing: ${vw(1)};
    margin-bottom: ${vw(58)};
    color: white;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(42)};
      line-height: ${vwTablet(50)};
      letter-spacing: ${vwTablet(0.84)};
      margin-bottom: ${vwTablet(30)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(30)};
      line-height: ${vwMobile(36)};
      letter-spacing: ${vwMobile(0.5)};
      margin-bottom: ${vwMobile(20)};
    }
  }
  img {
    width: 100%;
    height: auto;
    @media (max-width: ${media.sm}) {
      width: 90.9%;
      margin-top: ${vwTablet(60)};
    }
    @media (max-width: ${media.xs}) {
      width: 100%;
      margin-top: ${vwMobile(40)};
    }
  }
`;

const ContentColumnTwo = styled.div`
  display: flex;
  z-index: 1;
  @media (max-width: ${media.sm}) {
    flex-direction: column-reverse;
  }
`;

const Left = styled.div`
  width: 45.45%;
  @media (max-width: ${media.sm}) {
    width: 100%;
  }
`;

const Right = styled.div`
  margin-left: 9.05%;
  width: 45.45%;
  @media (max-width: ${media.sm}) {
    width: 100%;
    margin-left: 0;
  }
`;

const Info = styled.div`
  width: 83.33%;
  @media (max-width: ${media.xs}) {
    width: 91.67%;
  }
`;

const Intro = styled.p`
  font-family: ${({ theme }) => theme.fontBody};
  font-size: ${vw(20)};
  line-height: ${vw(30)};
  letter-spacing: ${vw(0.5)};
  color: white;
  a {
    color: ${({ theme }) => theme.colorDarkBlue};
  }
  @media (max-width: ${media.sm}) {
    font-size: ${vwTablet(18)};
    line-height: ${vwTablet(28)};
    letter-spacing: ${vwTablet(0.5)};
    color: white;
  }
  @media (max-width: ${media.xs}) {
    font-size: ${vwMobile(14)};
    line-height: ${vwMobile(20)};
    letter-spacing: 0;
  }
`;

// const Jobs = styled.div`
//   margin-bottom: ${vw(60)};
// `;

// const Job = styled.div`
//   margin-top: ${vw(30)};
//   @media (max-width: ${media.sm}) {
//     margin-top: ${vwTablet(20)};
//   }
//   @media (max-width: ${media.xs}) {
//     margin-top: ${vwMobile(20)};
//   }

//   h3 {
//     font-family: ${({ theme }) => theme.fontBody};
//     font-size: ${vw(20)};
//     font-weight: 600;
//     line-height: ${vw(32)};
//     letter-spacing: ${vw(1.5)};
//     color: white;
//     margin-bottom: ${vw(5)};
//     @media (max-width: ${media.sm}) {
//       font-size: ${vwTablet(20)};
//       line-height: ${vwTablet(32)};
//       letter-spacing: ${vwTablet(1.5)};
//     }
//     @media (max-width: ${media.xs}) {
//       font-size: ${vwMobile(16)};
//       line-height: ${vwMobile(24)};
//       letter-spacing: ${vwMobile(0.5)};
//     }
//   }
//   p {
//     opacity: 0.8;
//     font-family: ${({ theme }) => theme.fontBody};
//     font-size: ${vw(16)};
//     line-height: ${vw(26)};
//     margin-bottom: ${vw(30)};
//     color: white;
//     @media (max-width: ${media.sm}) {
//       font-size: ${vwTablet(16)};
//       line-height: ${vwTablet(26)};
//       margin-bottom: ${vwTablet(20)};
//     }
//     @media (max-width: ${media.xs}) {
//       font-size: ${vwMobile(14)};
//       line-height: ${vwMobile(18)};
//       letter-spacing: 0;
//     }
//   }
// `;

// const Email = styled.div`
//   margin-top: ${vw(15)};
//   @media (max-width: ${media.sm}) {
//     margin-top: ${vwTablet(10)};
//   }
//   @media (max-width: ${media.xs}) {
//     margin-top: ${vwMobile(10)};
//   }

//   a {
//     font-family: ${({ theme }) => theme.fontBody};
//     font-size: ${vw(20)};
//     line-height: ${vw(30)};
//     letter-spacing: ${vw(0.5)};
//     color: white;
//     position: relative;
//     @media (max-width: ${media.sm}) {
//       font-size: ${vwTablet(18)};
//       line-height: ${vwTablet(28)};
//       letter-spacing: ${vwTablet(0.5)};
//       color: white;
//     }
//     @media (max-width: ${media.xs}) {
//       font-size: ${vwMobile(14)};
//       line-height: ${vwMobile(18)};
//       letter-spacing: 0;
//     }
//     &::before {
//       content: '';
//       position: absolute;
//       bottom: -2px;
//       left: 0;
//       border-bottom: 1px solid ${({ theme }) => theme.colorOrange};
//       width: 0;
//       height: 1px;
//       transition: width 0.4s ease-out;
//     }
//     &:hover {
//       &::before {
//         width: 100%;
//       }
//     }
//   }
// `;

const BGShapeTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  & img {
    width: 100%;
    height: auto;
  }
`;

const BGShapeMiddle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
  & img {
    width: 100%;
    height: auto;
  }
`;

const BGShapeBottom = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
  & img {
    width: 100%;
    height: auto;
  }
`;

const mapStateToProps = state => ({
  posts: state.news.posts,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getNews,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withController(Careers));
