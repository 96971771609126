import React, { Fragment } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import ContentColumnTwo from 'components/ContentColumnTwo';
import Container from 'components/Container';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const paragraphs = [
  {
    title: 'Uncompromising in Action',
    body:
      'We are resolute in how we operate and follow a belief that "if we do it, we do it well". Everything we undertake is done to the best of our ability and we do not compromise on quality or our commitment.',
  },
  {
    title: 'Independent in Thought',
    body:
      'We define the world through our own experiences, judgement and conclusions. "Following the herd" is not part of our DNA and the ideas we form, actions we take, and results we seek come from innovative thinking.',
  },
  {
    title: 'Practical in Execution',
    body:
      'How we fulfill our vision, and how we execute our strategies and plans is always done in a way that is based on a proven methodology that is results-driven.',
  },
  {
    title: 'Creative in Approach',
    body:
      'Original ideas and discovering uncommon solutions that are designed to be uniquely effective is the foundation of how we approach challenges and opportunities.',
  },
  {
    title: 'Contemporary in Vision',
    body:
      'Our vision and how we see the world is founded on the ideas of today and tomorrow. We look for solutions that are innovative, forward thinking, and focussed on creating solutions that are enduring.',
  },
];

const Philosophy = () => {
  return (
    <Root>
      <BigLogo />
      <Container>
        {/* <Logo src={imgLogo} alt="Enera Logo" /> */}
        <h2>Philosophy</h2>
        <ContentColumnTwo
          left={
            <TitleText>
              Our investment and development philosophy are based on five key
              principles that form the foundation of everything we do.
            </TitleText>
          }
          right={
            <Fragment>
              {paragraphs.map((paragraph, index) => (
                <Paragraph key={index}>
                  <h3>{paragraph.title}</h3>
                  <p>{paragraph.body}</p>
                </Paragraph>
              ))}
            </Fragment>
          }
          breakpoint={media.sm}
        />
      </Container>
    </Root>
  );
};

export default Philosophy;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colorDarkBlue};
  background: white;
  margin-top: ${vw(-105)};
  padding-top: ${vw(214)};
  position: relative;
  @media (max-width: ${media.sm}) {
    padding-top: ${vwTablet(90)};
    padding-bottom: 10%;
    margin-top: 0;
  }

  h2 {
    font-family: ${({ theme }) => theme.fontHeader};
    font-size: ${vw(38)};
    font-weight: bold;
    line-height: ${vw(32)};
    margin-bottom: ${vw(40)};
    @media (max-width: ${media.sm}) {
      margin-bottom: ${vwTablet(30)};
      font-size: ${vwTablet(36)};
      line-height: ${vwTablet(32)};
    }
    @media (max-width: ${media.xs}) {
      margin-bottom: ${vwMobile(20)};
      font-size: ${vwMobile(24)};
      line-height: ${vwMobile(32)};
    }
  }
`;

const TitleText = styled.p`
  font-family: ${({ theme }) => theme.fontBody};
  letter-spacing: ${vw(0.5)};
  line-height: ${vw(30)};
  font-size: ${vw(20)};
  width: 75%;
  @media (max-width: ${media.sm}) {
    letter-spacing: ${vwTablet(0.5)};
    line-height: ${vwTablet(28)};
    font-size: ${vwTablet(18)};
    margin-bottom: ${vwTablet(60)};
    width: 100%;
  }
  @media (max-width: ${media.xs}) {
    letter-spacing: ${vwMobile(0.42)};
    line-height: ${vwMobile(19)};
    font-size: ${vwMobile(15)};
    margin-bottom: ${vwMobile(20)};
    width: 100%;
  }
`;

const Paragraph = styled.div`
  margin-bottom: ${vw(40)};
  h3 {
    font-family: ${({ theme }) => theme.fontBody};
    font-weight: 600;
    font-size: ${vw(20)};
    letter-spacing: ${vw(1.5)};
    line-height: ${vw(32)};
    text-transform: uppercase;
    margin-bottom: ${vw(10)};
    @media (max-width: ${media.sm}) {
      letter-spacing: ${vwTablet(1)};
      line-height: ${vwTablet(32)};
      font-size: ${vwTablet(18)};
      margin-bottom: 0;
    }
    @media (max-width: ${media.xs}) {
      letter-spacing: ${vwMobile(0.5)};
      line-height: ${vwMobile(24)};
      font-size: ${vwMobile(16)};
      margin-bottom: ${vwMobile(5)};
    }
  }
  p {
    font-family: ${({ theme }) => theme.fontBody};
    font-size: ${vw(16)};
    line-height: ${vw(26)};
    @media (max-width: ${media.sm}) {
      letter-spacing: 0;
      line-height: ${vwTablet(26)};
      font-size: ${vwTablet(16)};
      margin-bottom: ${vwTablet(30)};
    }
    @media (max-width: ${media.xs}) {
      letter-spacing: 0;
      line-height: ${vwMobile(18)};
      font-size: ${vwMobile(14)};
      margin-bottom: ${vwMobile(20)};
    }
  }
`;

const BigLogo = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  /* transform: translate3d(0, 50%, 0); */
  width: ${vw(377)};
  height: ${vw(871)};
  background: url(${require('src/assets/images/logo/large-logo-dark.svg')});
  background-size: cover;
  z-index: 1;
  @media (max-width: ${media.sm}) {
    top: 90%;
    width: ${vwTablet(187)};
    height: ${vwTablet(432)};
  }
  @media (max-width: ${media.sm}) {
    top: 95%;
    width: ${vwMobile(58)};
    height: ${vwMobile(138)};
  }
`;
