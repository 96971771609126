import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Page from 'components/Page';
import LastSection from 'components/LastSection';
import Landing from './sections/Landing';
import Redefine from './sections/Redefine';

class Home extends PureComponent {
  state = {
    smallLogo: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.checkScrollPosition);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScrollPosition);
  }

  checkScrollPosition = () => {
    if (window.pageYOffset > 20) {
      this.setState({ smallLogo: true });
    } else {
      this.setState({ smallLogo: false });
    }
  };

  render() {
    const { smallLogo } = this.state;
    return (
      <Root>
        <Page smallLogo={smallLogo}>
          <Landing />
        </Page>
        <LastSection>
          <Redefine />
        </LastSection>
      </Root>
    );
  }
}

export default Home;

const Root = styled.div``;