import React, { Component } from 'react';
import styled from 'styled-components';

import Logo from 'components/Logo';

class Layout extends Component {
  render() {
    return (
      <Root>
        <Logo />
        {this.props.children}
      </Root>
    );
  }
}

export default Layout;

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 990;
  height: 100vh;
  width: 100%;
  overflow: scroll;
`;
