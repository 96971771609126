import React, { PureComponent } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { Parallax, withController } from 'react-scroll-parallax';

import Page from 'components/Page';
import LastSection from 'components/LastSection';
import Container from 'components/Container';
import Description from 'components/Description';
import Hurd from './sections/Hurd';
import NewYork from './sections/NewYork';
import Fraser from './sections/Fraser';
import SunridgeWay from './sections/SunridgeWay';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const text = {
  title: 'Investments',
  body:
    'Committed to investing in prime revenue generating properties, our long-term approach is always based in a philosophy that our investments are based in sustainability. Along with this fundamental criteria, our investments also focus on primely located real estate and attaining properties under long-term leases to AAA tenants like institutions and governmental agencies. ',
  tagline: 'Sustainability',
};

class Investments extends PureComponent {
  handleLoad = () => {
    // updates cached values after image dimensions have loaded
    this.props.parallaxController.update();
  };

  render() {
    return (
      <Root>
        <BGShapeTop>
          <Parallax y={[-20, 1]}>
            <img
              src={require('src/assets/images/investments/bg-shapes/invest_shape_1.png')}
              alt="bg-shape"
              onLoad={this.handleLoad}
            />
          </Parallax>
        </BGShapeTop>
        <BGShapeBottom>
          <Parallax y={[-50, 15]}>
            <img
              src={require('src/assets/images/investments/bg-shapes/shape_2.png')}
              alt="bg-shape"
              onLoad={this.handleLoad}
            />
          </Parallax>
        </BGShapeBottom>
        <Page />
        <LastSection>
          <Container>
            {/* <Parallax offsetYMax={100} offsetYMin={-100}> */}
            <Description text={text} />
            {/* </Parallax> */}
          </Container>
          <Hurd />
          <NewYork />
          <Fraser />
          <SunridgeWay />
        </LastSection>
      </Root>
    );
  }
}

const Root = styled.div`
  background-color: #648dc6;
  margin-bottom: ${vw(575)};
  @media (max-width: ${media.sm}) {
    margin-bottom: ${vwTablet(420)};
  }
  @media (max-width: ${media.xs}) {
    margin-bottom: ${vwMobile(380)};
  }
`;

const BGShapeTop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  & img {
    width: 100%;
    height: auto;
  }
`;

const BGShapeBottom = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  & img {
    width: 100%;
    height: auto;
  }
`;

export default withController(Investments);
