import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const HeroCard = ({ label, text, to, image, imageTablet }) => {
  return (
    <Root backgroundimage={image} backgroundimagetablet={imageTablet} to={to}>
      <Container>
        <TextBlock>
          <h5>{label}</h5>
          <p>{text}</p>
        </TextBlock>
        <LinkButton>More</LinkButton>
      </Container>
    </Root>
  );
};

const Root = styled(Link)`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    opacity: 0.15;
  }
  &:hover {
    &::before {
      opacity: 1;
      transition: opacity 1.1s ease;
    }
    p {
      opacity: 1;
      transition: opacity 1.1s ease;
    }
    &  > div {
      max-height: 300px;
    }
    & > div > div {
      opacity: 1;
    }
  }

  @media (max-width: ${media.sm}) {
    height: auto;
    padding: ${vwTablet(77)} 0;
    &::before {
      background: url(${props => props.backgroundimagetablet});
      background-position: center 10%;
      opacity: 0.7;
    }
  }
  @media (max-width: ${media.xs}) {
    height: ${vwMobile(110)};
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      background: url(${props => props.backgroundimagetablet});
      background-position: center 10%;
      opacity: 0.7;
    }
  }
`;

const Container = styled.div`
  width: 81.81%;
  /* margin: 0 auto;
  padding: ${vw(60)} 0; */
  margin: ${vw(60)} auto;
  z-index: 1;
  position: relative;
  max-height: 0;
  transition: max-height 1.3s ease;
  @media (max-width: ${media.sm}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: unset;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      /* transform: translateY(50%); */
      transform: ${`translateY(${vwTablet(8)})`}; ;
      width: ${vwTablet(60)};
      height: ${vwTablet(2)};
      background-color: #ff8300;
    }
  }
  @media (max-width: ${media.xs}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 92.5%;
    &::before {
      display: none;
    }
  }
`;

const TextBlock = styled.div`
  font-family: ${({ theme }) => theme.fontBody};
  letter-spacing: ${vw(1)};
  color: white;
  
  @media (max-width: ${media.xs}) {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate3d(0, -50%, 0);
      background: url(${require('src/assets/images/home/arrow-right.svg')});
      background-size: cover;
      width: 10px;
      height: 16.6px;
    }
  }

  h5 {
    font-size: ${vw(18)};
    line-height: ${vw(18)};
    text-transform: uppercase;
    position: relative;
    margin-bottom: ${vw(40)};
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: ${vw(60)};
      height: ${vw(2)};
      background-color: #ff8300;
      opacity: 0.74;
      @media (max-width: ${media.sm}) {
        display: none;
      }
    }
  }
  p {
    opacity: 0;
    font-size: ${vw(18)};
    color: #ffffff;
    letter-spacing: ${vw(1)};
    line-height: ${vw(26)};
    margin-bottom: ${vw(40)};
  }
  @media (max-width: ${media.sm}) {
    letter-spacing: ${vwTablet(1)};
    margin-left: ${vwTablet(90)};
    h5 {
      font-size: ${vwTablet(18)};
      font-weight: 600;
      line-height: ${vwTablet(18)};
      margin-bottom: 0;
    }
    p {
      display: block;
      opacity: 1;
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(24)};
      margin-top: ${vwTablet(11)};
      margin-bottom: 0;
    }
  }
  @media (max-width: ${media.xs}) {
    margin-left: 0;
    h5 {
      font-size: ${vwMobile(16)};
      font-weight: 600;
      line-height: ${vwMobile(22)};
      letter-spacing: ${vwMobile(1)};
    }
    p {
      display: block;
      font-size: ${vwMobile(14)};
      line-height: ${vwMobile(18)};
      margin-top: ${vwMobile(11)};
      letter-spacing: ${vwMobile(0.5)};
    }
  }
`;

const LinkButton = styled.div`
  width: 80px;
  height: 30px;
  background-color: #ff8300;
  display: flex;
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  justify-content: center;
  align-items: center;
  opacity: 0;

  @media (max-width: ${media.sm}) {
    width: ${vwTablet(80)};
    height: ${vwTablet(30)};
    font-size: ${vwTablet(16)};
    line-height: ${vwTablet(16)};
    letter-spacing: ${vwTablet(0.5)};
    opacity: 1;
  }
  @media (max-width: ${media.xs}) {
    display: none;
  }
`;

export default HeroCard;
