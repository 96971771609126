import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import media from 'src/styles/media';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const Card = ({ post }) => {
  return (
    <Root to={`/news/${post.id}/${post.slug}`}>
      {post._embedded['wp:featuredmedia'] ? (
        <ImageBlock
          image={post._embedded['wp:featuredmedia'][0].source_url}
          bgPositionBottom={post.title.rendered==='Property Sold'}
        />
      ) : (
        <ImageBlock image={require('src/assets/images/logo/e_logo.svg')} />
      )}
      <Info>
        <h4>{dayjs(post.date).format('MMMM D, YYYY')}</h4>
        <h3 dangerouslySetInnerHTML={{ __html: post.title.rendered }}></h3>
      </Info>
    </Root>
  );
};

const Root = styled(Link)`
  position: relative;
  margin-bottom: ${vw(40)};
  display: block;
  @media (max-width: ${media.sm}) {
    margin-bottom: ${vwTablet(20)};
  }
  @media (max-width: ${media.xs}) {
    margin-bottom: ${vwMobile(20)};
  }
`;

const ImageBlock = styled.div`
  width: 72.72%;
  height: ${vw(400)};
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${props => props.image});
    background-size: cover;
    background-position: ${props => props.bgPositionBottom ? 'bottom' : 'center center'};
    transition: transform 0.8s ease;
  }
  &:hover {
    &::before {
      transform: scale(1.05);
    }
  }
  @media (max-width: ${media.sm}) {
    width: 83.33%;
    height: ${vwTablet(300)};
  }
  @media (max-width: ${media.xs}) {
    width: 100%;
    height: ${vwMobile(150)};
  }
`;

const Info = styled.div`
  font-family: ${({ theme }) => theme.fontBody};
  background: #00205c;
  padding: ${vw(40)};
  color: white;
  width: 36.33%;
  position: absolute;
  top: ${vw(40)};
  right: 0;
  transform: translate3d(-50%, 0, 0);
  overflow: hidden;
  @media (max-width: ${media.sm}) {
    padding: ${vwTablet(40)};
    top: ${vwTablet(40)};
    width: 63.63%;
    transform: translate3d(0, 0, 0);
  }
  @media (max-width: ${media.xs}) {
    position: unset;
    width: 100%;
    padding: ${vwMobile(20)};
  }

  :after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 390%;
    width: 180%;
    background: ${({ theme }) => theme.colorOrange};
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    transform: translateX(110%) translateY(-15%) rotate(45deg);
  }
  :hover {
    h3 {
      color: ${({ theme }) => theme.colorOrange};
    }
    /* transform: translateX(15%) translateY(-25%) rotate(45deg); */
  }

  h4 {
    opacity: 0.8;
    font-size: ${vw(13)};
    line-height: ${vw(13)};
    letter-spacing: ${vw(1.5)};
    margin-bottom: ${vw(5)};
    z-index: 2;
    position: relative;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(13)};
      line-height: ${vwTablet(13)};
      letter-spacing: ${vwTablet(1.5)};
      margin-bottom: ${vwTablet(5)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(12)};
      line-height: ${vwMobile(12)};
      letter-spacing: ${vwMobile(1)};
      margin-bottom: ${vwMobile(6)};
    }
  }
  h3 {
    font-size: ${vw(20)};
    font-weight: 600;
    line-height: ${vw(32)};
    letter-spacing: ${vw(1.5)};
    z-index: 2;
    position: relative;
    text-transform: uppercase;
    transition: color 0.4s ease-out;
    @media (max-width: ${media.sm}) {
      font-size: ${vwTablet(18)};
      line-height: ${vwTablet(32)};
      letter-spacing: ${vwTablet(1.35)};
    }
    @media (max-width: ${media.xs}) {
      font-size: ${vwMobile(16)};
      line-height: ${vwMobile(24)};
      letter-spacing: ${vwMobile(0.5)};
    }
  }
`;

export default Card;
